import React, {useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import './select.scss';

const Select = ({languageId, options, selectedId, onSelect}) => {
	const [dropdownIsOpen, setDropDownIsOpen] = useState(false);
	const selectRef = useRef();

	const toggleDropdown = () => {setDropDownIsOpen(!dropdownIsOpen);};
	const handleSelect = (optionId) =>  {
		onSelect(optionId);
		toggleDropdown();
	};

	const useClickOutside = (ref, onClickOutside) => {
		useEffect(() => {
			/**
			 * Invoke Function onClick outside of element
			 */
			function handleClickOutside(event) {
				if (ref.current && !ref.current.contains(event.target)) {
					onClickOutside();
				}
			}
			// Bind
			document.addEventListener('mousedown', handleClickOutside);
			return () => {
				// dispose
				document.removeEventListener('mousedown', handleClickOutside);
			};
		}, [ref, onClickOutside]);
	};
	useClickOutside(selectRef, () => {
		setDropDownIsOpen(false);
	});

	const getOptionValue = (option) => {
		if (!option) return '-';

		let value = option.id;
		if (option.name) {
			value = option.name[languageId];
		} else if (option.title) {
			value = option.title[languageId];
		}
		return value;
	};

	const selectedOption = options.find((option) => {return option.id === selectedId;});
	
	return (
		<div className={'Select' + (dropdownIsOpen ? ' open' : '')} ref={selectRef}>
			<div className={'Select-panel Select-panel--' + selectedId} onClick={()=>{toggleDropdown();}}>
				<div className="Select-selected"><span>{getOptionValue(selectedOption)}</span></div>
				<div className="Select-button" />
			</div>
			<div className="Select-dropdown">
				{options.map((option, index) => {
					return (
						<div 
							key={index} 
							className={'Select-option ' + option.id + (selectedId === option.id ? ' selected' : '')} 
							onClick={()=>{handleSelect(option.id);}}
						><span>{getOptionValue(option)}</span></div>
					);
				})}
			</div>
		</div>
	);
};

Select.defaultProps = {
	isDisabled: false
};


Select.propTypes = {
	languageId: PropTypes.string.isRequired,
	options: PropTypes.array.isRequired,
	selectedId: PropTypes.string,
	onSelect: PropTypes.func.isRequired,
};

export default Select;
