import React from 'react';
import PropTypes from 'prop-types';
import LocationPanel from './location-panel';
import LanguagesPanel from './languages-panel';
import StatusPanel from './status-panel';
import SortingPanel from './sorting-panel';
import './filters.scss';

const Filters = (props) => {
	const {
		isLoggedIn,
		showClosed,
		showFuture,
		hideCompleted,
		languageId,
		page,
		regionId,
		cityId,
		excludedLanguages,
		sortBy,
		sortDirection,
		setShowClosed,
		setShowFuture,
		handleSelectRegionId,
		setCityId,
		toggleLanguage,
		setHideCompleted,
		handleSort,
		handleSortDirection
	} = props;

	return (
		<div className="Filters">
			<div className="Filters-location">
				<LocationPanel 
					languageId={languageId}
					regionId={regionId}
					cityId={cityId}
					handleSelectRegionId={handleSelectRegionId}
					setCityId={setCityId}
				/>
			</div>
			
			<div className="Filters-language">
				<LanguagesPanel 
					languageId={languageId} 
					excludedLanguages={excludedLanguages} 
					toggleLanguage={toggleLanguage}
				/>
			</div>

			<div className="Filters-status">
				<StatusPanel 
					isLoggedIn={isLoggedIn}
					languageId={languageId}
					showClosed={showClosed}
					showFuture={showFuture}
					hideCompleted={hideCompleted}
					setShowClosed={setShowClosed}
					setShowFuture={setShowFuture}
					setHideCompleted={setHideCompleted}
				/>
			</div>

			<div className={'Filters-sorting ' + page}>
				<SortingPanel 
					languageId={languageId}
					page={page}
					sortBy={sortBy}
					sortDirection={sortDirection}
					handleSort={handleSort}
					handleSortDirection={handleSortDirection}
				/>
			</div>
		</div>
	);
};


Filters.propTypes = {
	isLoggedIn: PropTypes.bool.isRequired,
	showClosed: PropTypes.bool.isRequired,
	showFuture: PropTypes.bool.isRequired,
	hideCompleted: PropTypes.bool.isRequired,
	languageId: PropTypes.string.isRequired,
	page: PropTypes.string.isRequired,
	regionId: PropTypes.string.isRequired,
	cityId: PropTypes.string.isRequired,
	excludedLanguages: PropTypes.array.isRequired,
	sortBy: PropTypes.string.isRequired,
	sortDirection: PropTypes.string.isRequired,
	setShowClosed: PropTypes.func.isRequired,
	setShowFuture: PropTypes.func.isRequired,
	handleSelectRegionId: PropTypes.func.isRequired,
	setCityId: PropTypes.func.isRequired,
	toggleLanguage: PropTypes.func.isRequired,
	setHideCompleted: PropTypes.func.isRequired,
	handleSort: PropTypes.func.isRequired,
	handleSortDirection: PropTypes.func.isRequired,
};


export default Filters;
