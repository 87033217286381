const regionsData = [
	{
		id: 'jutland',
		name: {
			da: 'Jylland', 
			en: 'Jutland'
		},
	},
	{
		id: 'sealand',
		name: {
			da: 'Sjælland',
			en: 'Sealand'
		},
	},
	{
		id: 'funen',
		name: {
			da: 'Fyn & øerne',
			en: 'Funen & the isles'
		}
	}
];

export {regionsData};