import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {renderMarkdown} from 'helpers/markdown-helper';
import {uiTexts} from 'data/ui-texts';
import './popup-about.scss';

const PopupAbout = ({languageId, deviceInfo}) => {
	/* Scroll to top */
	useEffect(() => {window.scrollTo(0, 0);});

	
	return (
		<div className={'PopupAbout ' + deviceInfo.orientation}>
			<Link className="PopupAbout-closeLayer" to={'/'} />
			<div className="PopupAbout-content">
				<div className="PopupAbout-header">
					<div className="PopupAbout-title"><span>{uiTexts.popupAbout.title[languageId]}</span></div>
					<Link className="PopupAbout-closeBtn" to={'/'} />
				</div>
				<div className="PopupAbout-body">
					<div className="PopupAbout-text">
						{renderMarkdown(uiTexts.popupAbout.text[languageId])}
					</div>
					{languageId === 'da' && <div className="PopupAbout-text">
						{renderMarkdown(uiTexts.popupAbout.fbText[languageId])}
					</div>}
				</div>	
			</div>
		</div>
	);
};

PopupAbout.propTypes = {
	languageId: PropTypes.string.isRequired,
	deviceInfo: PropTypes.object.isRequired
};

export default PopupAbout;