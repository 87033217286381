const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'timequest',
	name: 'TimeQuest',
	website: 'http://www.timequest.nu',
	locations: [{
		id: 'copenhagen',
		cityId: 'copenhagen',
		opened: '20130000',
		closed: null,
		address: 'Gothersgade 101 C, 1123 Købehhavn K',
		coordinates: {lat: '55.683440', lng: '12.576610'},
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'alcatraz',
			name: {da: 'Alcatraz', en: 'Alcatraz'},
			languages: ['da', 'en'],
			teamSizeMin: 4,
			minimumAge: 10,
			minimumAgeNoAdult: 15,
			difficulty: 100,
			locations: [{
				locationId: 'copenhagen',
				opened: '20170901',
				closed: '20211213',
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'follow-the-money',
			name: {da: 'Bedrag', en: 'Follow the Money'},
			languages: ['da', 'en'],
			teamSizeMin: 3,
			minimumAge: 10,
			minimumAgeNoAdult: 15,
			difficulty: 65,
			locations: [{
				locationId: 'copenhagen',
				opened: '20160229',
				closed: true,
				rooms: 2
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'prison-break',
			name: {da: 'Prison Break', en: 'Prison Break'},
			languages: ['da', 'en'],
			teamSizeMin: 2,
			teamSizeMax: 2,
			minimumAge: 10,
			minimumAgeNoAdult: 15,
			difficulty: 40,
			tagIds: ['beginners'],
			locations: [{
				locationId: 'copenhagen',
				opened: '20141215',
				closed: '20211213',
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-bank',
			name: {da: 'Banken', en: 'The Bank'},
			languages: ['da', 'en'],
			teamSizeMin: 4,
			minimumAge: 10,
			minimumAgeNoAdult: 15,
			difficulty: 60,
			locations: [{
				locationId: 'copenhagen',
				opened: '20130101',
				closed: '20211213',
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-laboratory',
			name: {da: 'Laboratoriet', en: 'The Laboratory'},
			languages: ['da', 'en'],
			teamSizeMin: 4,
			minimumAge: 10,
			minimumAgeNoAdult: 15,
			difficulty: 60,
			tagIds: ['beginners'],
			locations: [{
				locationId: 'copenhagen',
				opened: '20130101',
				closed: '20211213',
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-olsen-gang',
			name: {da: 'Olsenbanden', en: 'The Olsen Gang'},
			languages: ['da', 'en'],
			teamSizeMin: 4,
			minimumAge: 10,
			minimumAgeNoAdult: 15,
			difficulty: 80,
			locations: [{
				locationId: 'copenhagen',
				opened: '20180329',
				closed: '20211213',
				rooms: 2
			}]
		})
		
	]
});


export {venueData};