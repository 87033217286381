import React from 'react';
import PropTypes from 'prop-types';
import {languagesData} from 'data/languages-data';
import './language-icons.scss';

const LanguageIcons = ({type, languageId, languageIds}) => {
	let languages = JSON.parse(JSON.stringify(languagesData));
	languages.forEach((language) => {
		language.included = languageIds.indexOf(language.id) >= 0;
	});

	return (
		<div className="LanguageIcons">
			{languages.map((language) => {
				if (language.included) {
					let className = 'LanguageIcons-icon ' + language.id;
					if (type) className += ' ' + type;
					if (language.included) className += ' included';
					return (
						<div 
							key={language.id} 
							className={className} 
						/>
					);	
				}
				return null;
			})}
		</div>
	);	
};

LanguageIcons.defaultProps = {
	type: null,
	languageId: 'en',
	languageIds: []
};

LanguageIcons.propTypes = {
	type: PropTypes.string,
	languageId: PropTypes.string.isRequired,
	languageIds: PropTypes.array.isRequired,
};



export default LanguageIcons;