const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'vingsted',
	name: 'Vingsted',	
	website: 'https://www.vingsted.dk',
	locations: [{
		id: 'vejle',
		cityId: 'vejle',
		opened: '20170601',
		closed: null,
		address: 'Vingsted Skovvej 2, 7182 Bredsten',
		coordinates: {lat: '55.675096', lng: '9.380303'},
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'escape-house',
			name: {da: 'Escape House', en: 'Escape House'},			
			teamSizeMin: 4,
			teamSizeMax: 7,
			minimumAge: 18,
			locations: [{
				locationId: 'vejle',
				opened: '20170629',
				closed: '20200301',
				rooms: 3
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-vikings-return',
			name: {da: 'Vikingerne vender tilbage', en: 'The Vikings Return'},
			teamSizeMin: 4,
			teamSizeMax: 7,
			minimumAge: 18,
			locations: [{
				locationId: 'vejle',
				opened: '20201101',
				closed: true,
				rooms: 3
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'adventurers-secret-treasure',
			name: {da: 'Eventyrerens hemmelige skat', en: 'The adventurer\'s secret treasure'},
			teamSizeMin: 4,
			teamSizeMax: 7,
			minimumAge: 18,
			locations: [{
				locationId: 'vejle',
				opened: '20230319',
				closed: null,
				rooms: 3
			}]
		}),
	]
});


export {venueData};