import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import './popup-map.scss';

const PopupMap = ({languageId, deviceInfo}) => {
	useEffect(() => {window.scrollTo(0, 0);});
	let zoom = (window.innerWidth <= 720 ? 6 : 7);
	return (
		<div className={'PopupMap ' + deviceInfo.orientation}>
			<Link className="PopupMap-closeLayer" to={'/'} />
			<div className="PopupMap-content">
				<Link className="PopupMap-closeBtn" to={'/'} />
				<iframe 
					title="Escapades"
					src={
						'https://www.google.com/maps/d/embed?mid=1-owXGo2Qxct3cYAUEO57Qo5AAiIFISl-' +
						'&ll=' + 56.4 + ',' + 10.5 + 
						'&z=' + zoom + 
						'&hl=' + languageId
					}
					width="100%" 
					height="100%" />
			</div>
		</div>
	);
};


PopupMap.propTypes = {
	languageId: PropTypes.string.isRequired,
	deviceInfo: PropTypes.object.isRequired,
};


export default PopupMap;