import React, {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const Auth = ({setIsLoggedIn, setUserData, children}) => {
	/* Auth state subscription */
	const unsubscribeOnAuthStateChanged = useRef(null);

	/* User data subscription */
	const unsubscribeUserData = useRef(null);

	/**
	 * Check if user is logged in 
	 */
	const checkIfLoggedIn = () => {
		/* Cancel previous subscription */
		if (unsubscribeOnAuthStateChanged.current !== null) {
			unsubscribeOnAuthStateChanged.current();
		}

		/* Subscribe to onAuthStateChanged */
		unsubscribeOnAuthStateChanged.current = firebase.auth().onAuthStateChanged((user)=>{
			if (user) {
				/* User is logged in */
				setIsLoggedIn(true);
				subscribeToUserData(user.uid);
			} else {
				/* Not logged in */
				setIsLoggedIn(false);
				setUserData(null);
			}
		});
	};


	/**
	 * Subscribe to user data
	 * @param {string} userId 
	 * @returns 
	 */
	const subscribeToUserData = (userId) => {
		/* Cancel previous subscription */
		if (unsubscribeUserData.current) unsubscribeUserData.current();

		/* Subscribe to modules data */
		const db = firebase.firestore();
		unsubscribeUserData.current = db.collection('users').doc(userId).onSnapshot((userDoc) => {
			if (userDoc && userDoc.data) {
				const userData = {...userDoc.data(), id: userDoc.id};
				setUserData(userData);
			}
		}, (error) => {
			console.error(error);
		});
	};

	/**
	 * Logout
	 */
	const handleLogout = () => {
		firebase.auth().signOut().then(() => {
			setIsLoggedIn(false);
			setUserData(null);
		}).catch((error) => {
			console.error(error);
		});
	};

	/**
	 * Component did mount / will unmount
	 */
	useEffect(() => {
		/* Check if logged in */
		checkIfLoggedIn();
		
		return () => {
			/* Cancel subscriptions */
			if (unsubscribeOnAuthStateChanged.current) {
				unsubscribeOnAuthStateChanged.current();
			}
			if (unsubscribeUserData.current) {
				unsubscribeUserData.current();
			}
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	
	const childrenWithProps = React.Children.map(children, (child) => {
		if (React.isValidElement(child)) {
			const clonedChild = React.cloneElement(child, {handleLogout});
			return clonedChild;
		}
		return child;
	});
	return childrenWithProps;
};


Auth.propTypes = {
	setIsLoggedIn: PropTypes.func.isRequired,
	setUserData: PropTypes.func.isRequired,
	children: PropTypes.array.isRequired
};

export default Auth;