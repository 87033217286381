const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'countdown-escaperooms',
	name: 'Countdown Escape Rooms',	
	website: 'https://countdown-escaperooms.dk/',
	locations: [
		{
			id: 'copenhagen',
			cityId: 'copenhagen',
			opened: '20240301',
			closed: '20240822',
			address: 'Tornebuskegade 1, 1131 København',
		},
		{
			id: 'lyngby',
			cityId: 'lyngby',
			opened: '20241001',
			closed: null,
			address: 'Jernbanepladsen 65, 2880 Lyngby'
		}
],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'the-curse-of-the-mummy',
			name: {da: 'Mumiens Forbandelse', en: 'The Curse of the Mummy'},			
			languages: ['en'],
			teamSizeMax: 6,
			teamSizeMin: 2,
			minimumAgeNoAdult: 15,
			locations: [
				{
					locationId: 'copenhagen',
					opened: '20240301',
					closed: '20240822',
					rooms: 1
				},
				{
					locationId: 'lyngby',
					opened: '20241001',
					closed: null,
					rooms: 1
				}
			]
		}),
		Object.assign({}, roomTemplate, {
			id: 'professors-mad-plan',
			name: {da: 'The Professor\s Mad Plan', en: 'The Professor\s Mad Plan'},			
			languages: ['en'],
			teamSizeMax: 6,
			teamSizeMin: 2,
			minimumAgeNoAdult: 15,
			locations: [{
				locationId: 'lyngby',
				opened: '20241014',
				closed: null,
				rooms: 2
			}]
		}),
	]
});


export {venueData};