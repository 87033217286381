const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'museum-obscurum',
	name: 'Museum Obscurum',	
	website: 'https://aabne-samlinger.dk/falstersminder/falsters-minder/museum-obscurum/escape-room/',
	locations: [{
		id: 'nykoebing-falster',
		cityId: 'nykoebing-falster',
		opened: '20190801',
		closed: null,
		address: 'Frisegade 40, 4800 Nykøbing Falster',
		coordinates: {lng: '11.868528', lat: '54.76384'},
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'cornelius-secret',
			name: {da: 'Cornelius\' Hemmelighed', en: 'Cornelius\' Secret'},
			teamSizeMin: 4,
			teamSizeMax: 7,
			locations: [{
				locationId: 'nykoebing-falster',
				opened: '20190801',
				closed: null,
				rooms: 1
			}]
		}),
	]
});


export {venueData};