const languagesData = [
	{
		id: 'da',
		title: {
			da: 'Dansk', 
			en: 'Danish'
		},
		isValidPageLanguage: true,
		selected: true, 
		localization: 'da-DA'
	},
	{
		id: 'en',
		title: {
			da: 'Engelsk',
			en: 'English'
		},
		isValidPageLanguage: true,
		selected: true, 
		localization: 'en-GB'
	},
	{
		id: 'de',
		title: {
			da: 'Tysk',
			en: 'German'
		},
		isValidPageLanguage: false,
		selected: true, 
	}
];

export {languagesData};