const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'amaze',
	name: 'Amaze',
	website: 'http://www.amazeroom.dk',
	locations: [{
		id: 'odense',
		cityId: 'odense',
		opened: '20160801',
		closed: null,
		coordinates: {lng: '10.393264', lat: '55.397386'}
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'mafia',
			name: {da: 'Mafia', en: 'Mafia'},
			languages: ['da', 'en'],
			teamSizeMax: 4,
			minimumAge: 14,
			difficulty: 75,
			locations: [{
				locationId: 'odense',
				opened: '20171101',
				closed: null
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'pirate-island',
			name: {da: 'Pirate Island', en: 'Pirate Island'},
			languages: ['da', 'en'],
			minimumAge: 14,
			difficulty: 50,
			tagIds: ['beginners'],
			locations: [{
				locationId: 'odense',
				opened: '20160801',
				closed: null
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'tomb',
			name: {da: 'Tomb', en: 'Tomb'},
			languages: ['da', 'en'],
			minimumAge: 14,
			difficulty: 75,
			locations: [{
				locationId: 'odense',
				opened: '20160801',
				closed: null
			}]
		})
	]
});


export {venueData};