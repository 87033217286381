const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'escape-factory',
	name: 'Escape Factory',	
	website: 'http://www.escapefactory.dk',
	locations: [{
		id: 'aarhus',
		cityId: 'aarhus',
		opened: '20150430',
		closed: null,
		address: 'Mejlgade 52 st., 8000 Aarhus',
		coordinates: {lng: '10.213137', lat: '56.160018'},
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'enigma',
			name: {da: 'Enigma', en: 'Enigma'},
			languages: ['da', 'en'],
			teamSizeMax: 5,
			minimumAgeNoAdult: 13,
			difficulty: 90,
			locations: [{
				locationId: 'aarhus',
				opened: '20150430',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-alchemist',
			name: {da: 'Alkymisten', en: 'The Alchemist'},
			languages: ['da', 'en'],
			teamSizeMax: 5,
			minimumAgeNoAdult: 13,
			difficulty: 80,
			tagIds: ['battle-room'],
			locations: [{
				locationId: 'aarhus',
				opened: '20150430',
				closed: null,
				rooms: 2
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-murder-of-colonel-fitzpatrick',
			name: {da: 'Mordet på Oberst Fitzpatrick', en: 'The Murder of Colonel Fitzpatrick'},
			languages: ['da', 'en'],
			teamSizeMax: 5,
			minimumAgeNoAdult: 13,
			difficulty: 90,
			tagIds: ['battle-room'],
			locations: [{
				locationId: 'aarhus',
				opened: '20180418',
				closed: null,
				rooms: 2
			}]
		})
	]
});


export {venueData};