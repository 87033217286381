import React from 'react';
import PropTypes from 'prop-types';
import {uiTexts} from 'data/ui-texts';
import {languagesData} from 'data/languages-data';
import './header.scss';

const Header = ({isLoggedIn, languageId, deviceInfo, setLanguageId, handleLogout}) => {
	
	return (
		<div className={'Header ' + deviceInfo.orientation}>
			{/* Title */}
			<div className="Header-title" onClick={()=>{window.location.href = '/';}}>
				<span>{uiTexts.title}</span>
				{' - ' + uiTexts.subtitle[languageId] + ' '}
				{(deviceInfo.orientation === 'portrait' ? uiTexts.dk[languageId] : uiTexts.denmark[languageId])}
			</div>

			{/* Page languages */}
			<div className="Header-languages">
				{languagesData.map((language) => {
					if (!language.isValidPageLanguage) return null;
					return (
						<div 
							key={language.id}
							className={'Header-language ' + language.id 
								+ (languageId === language.id ? ' selected' : '')}
							onClick={() => {setLanguageId(language.id);}}
						>
							<span>{language.title[languageId]}</span>
						</div>
					);
				})}
			</div>

			{/* Logout */}
			{(isLoggedIn && handleLogout) && <div className="Header-logout" onClick={() => {handleLogout();}}/>}
		</div>
	);
};

Header.propTypes = {
	isLoggedIn: PropTypes.bool.isRequired,
	languageId: PropTypes.string.isRequired,
	deviceInfo: PropTypes.object.isRequired,
	setLanguageId: PropTypes.func.isRequired,
	handleLogout: PropTypes.func
};



export default Header;