import React from 'react';
import PropTypes from 'prop-types';
import {sortArrayByProperty} from 'helpers/array-helpers';
import {uiTexts} from 'data/ui-texts';
import {regionsData} from 'data/regions-data';
import {citiesData} from 'data/cities-data';
import Select from '../select/select';
import './location-panel.scss';

const LocationPanel = ({languageId, regionId, cityId, handleSelectRegionId, setCityId}) => {
	/* Region options */
	const regionOptions = [{id: 'denmark', name: uiTexts.denmark}].concat(regionsData);
	
	/* City options */
	const cityOptions = sortArrayByProperty(citiesData.filter((city) => {
		return (
			regionId === 'denmark' ||
			city.regionId === regionId
		);
	}), 'name', 'ASC', null, null, null, null, languageId);
	cityOptions.splice(0, 0, {id: 'all-cities', name: uiTexts.allCities});

	return (
		<div className="LocationPanel">
			<div className="LocationPanel-title">{uiTexts.regions[languageId]}:</div>
			<div className="LocationPanel-dropdown region">
				<Select 
					languageId={languageId}
					options={regionOptions}
					selectedId={regionId}
					onSelect={handleSelectRegionId}
				/>
			</div>
			<div className="LocationPanel-dropdown city">
				<Select 
					languageId={languageId}
					options={cityOptions}
					selectedId={cityId}
					onSelect={setCityId}
				/>
			</div>
		</div>
	);
};

LocationPanel.propTypes = {
	languageId: PropTypes.string.isRequired,
	regionId: PropTypes.string.isRequired,
	cityId: PropTypes.string.isRequired,
	handleSelectRegionId: PropTypes.func.isRequired,
	setCityId: PropTypes.func.isRequired
};

export default LocationPanel;