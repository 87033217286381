const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'froeslevlejrens-museum',
	name: 'Frøslevlejrens Museum',
	website: 'https://natmus.dk/museer-og-slotte/froeslevlejrens-museum/',
	locations: [{
		id: 'padborg',
		cityId: 'padborg',
		opened: '20130301',
		closed: null,
		address: 'Lejrvejen 83, 6330 Padborg',
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'the-escape-from-froeslevlejren',
			name: {da: 'Flugten fra Frøslevlejren', en: 'The Escape from Frøslevlejren'},
			teamSizeMax: 5,
			minutes: 35,
			locations: [{
				locationId: 'padborg',
				opened: '20220701',
				closed: null,
				rooms: 1
			}]
		}),
		
	]
});


export {venueData};