const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'escape-history-house',
	name: 'Escape History House',	
	website: 'http://www.escape-history.dk',
	locations: [{
		id: 'esbjerg',
		cityId: 'esbjerg',
		opened: '20180730',
		closed: null,
		address: 'Baunhøjvej 38, 6840 Oksbøl',
		coordinates: {lat: '55.638555', lng: '8.281601'},
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'escape-land-of-mine',
			name: {da: 'Under Sandet', en: 'Escape Land of Mine'},			
			languages: ['da', 'en', 'de'],
			minimumAge: 8,
			minimumAgeNoAdult: 18,
			difficulty: 50,
			locations: [{
				locationId: 'esbjerg',
				opened: '20190701',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'escape-tirpitz',
			name: {da: 'Escape Tirpitz', en: 'Escape Tirpitz'},
			languages: ['da', 'en', 'de'],
			minimumAge: 8,
			minimumAgeNoAdult: 18,
			difficulty: 50,
			locations: [{
				locationId: 'esbjerg',
				opened: '20180730',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'illuminati-cabin',
			name: {da: 'Illuminati Hytte', en: 'Illuminati Cabin'},
			languages: ['da', 'en', 'de'],
			minutes: 1200,
			teamSizeMax: 4,
			minimumAge: 15,
			minimumAgeNoAdult: 18,
			difficulty: 75,
			locations: [{
				locationId: 'esbjerg',
				opened: '20210701',
				closed: null,
				rooms: 1
			}]
		})
	]
});


export {venueData};