const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'escape-zone',
	name: 'Escape Zone',
	website: 'http://www.escapezone.dk',
	locations: [{
		id: 'esbjerg',
		cityId: 'esbjerg',
		opened: '20181005',
		address: 'Darumvej 41, 6700 Esbjerg',
		coordinates: {lng: '8.47399', lat: '55.46251'},
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'bomb-squad',
			name: {da: 'Bomb Squad', en: 'Bomb Squad'},
			languages: ['en'],
			minimumAge: 12,
			difficulty: 68,
			tagIds: ['kids', 'beginners'],
			locations: [{
				locationId: 'esbjerg',
				opened: '20190830',
				closed: '20210701',
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'kidnapped',
			name: {da: 'Kidnapped 2.0', en: 'Kidnapped 2.0'},
			languages: ['en'],
			minimumAge: 13,
			difficulty: 70,
			locations: [{
				locationId: 'esbjerg',
				opened: '20190830',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'mission-impossible',
			name: {da: 'Mission Impossible', en: 'Mission Impossible'},
			languages: ['en'],
			minimumAge: 12,
			locations: [{
				locationId: 'esbjerg',
				opened: '20210730',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'prison-break',
			name: {da: 'Prison Break', en: 'Prison Break'},
			languages: ['en'],
			minimumAge: 13,
			difficulty: 72,
			locations: [{
				locationId: 'esbjerg',
				opened: '20201101',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-ring',
			name: {da: 'The Ring', en: 'The Ring'},
			languages: ['en'],
			minimumAge: 13,
			difficulty: 70,
			locations: [{
				locationId: 'esbjerg',
				opened: '20190830',
				closed: '20201001',
				rooms: 1
			}]
		}),
	]
});


export {venueData};