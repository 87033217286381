const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'nerd-cave',
	name: 'Nerd Cave',	
	website: 'https://escape.nerdcave.dk',
	locations: [{
		id: 'grenaa',
		cityId: 'grenaa',
		opened: '20211126',
		closed: null,
		address: 'Storegade 14A, 8500 Grenaa',
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'the-chemical-bomb',
			name: {da: 'Den Kemiske Bombe', en: 'The Chemical Bomb'},			
			languages: ['da'],
			teamSizeMax: 4,
			teamSizeMin: 2,
			minimumAge: 7,
			minimumAgeNoAdult: 16,
			locations: [{
				locationId: 'grenaa',
				opened: '20230530',
				closed: null,
				rooms: 1
			}]
		}),
	]
});


export {venueData};