import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Link, useParams} from 'react-router-dom';
import {renderMarkdown} from 'helpers/markdown-helper';
import {checkIfLocationIsClosed } from 'helpers/venues-helper';
import {sortArrayByProperty} from 'helpers/array-helpers';
import {uiTexts} from 'data/ui-texts';
import {venuesAndRooms} from 'data/venues-and-rooms-data';
import {citiesData} from 'data/cities-data';
import Rooms from 'components/rooms/rooms';
import './popup-venue.scss';

const PopupVenue = ({languageId, deviceInfo, userData}) => {
	/* Scroll to top */
	useEffect(() => {window.scrollTo(0, 0);});

	/* Get venueId from route params */
	const {venueId} = useParams();

	/* Get venue data */
	const venueData = venuesAndRooms.find((v) => {return v.id === venueId;});

	/* Sort locations by city name */
	let locations = [];
	if (venueData && venueData.locations) {
		venueData.locations.forEach((location) => {
			let locObj = JSON.parse(JSON.stringify(location));
			const cityData = citiesData.find((c) => {return c.id === location.cityId;});
			if (cityData) {
				locObj.cityName = cityData.name[languageId];
				locations.push(locObj);
			}
		});
		locations = sortArrayByProperty(locations, 'cityName', 'ASC');
	}


	let website = null;
	if (venueData && venueData.website) {
		website = venueData.website
			.replace('https://www.', '')
			.replace('http://www.', '')
			.replace('http://', '')
			.replace('https://', '')
			.replace('www.', '');
		
	}

	/* Venue description */
	try {
		if (venueData) {
			venueData.description = require('data/rooms/' +  venueId + '/venue_' + languageId + '.md').default;
		}
	} catch (error) {
		console.error(error);
	}

	/* No venue data */
	if (!venueData) return null;

	
	return (
		<div className={'PopupVenue ' + deviceInfo.orientation}>
			<Link className="PopupVenue-closeLayer" to={'/'} />
			<div className="PopupVenue-content">
				<div className="PopupVenue-header">
					<div className="PopupVenue-title"><span>{venueData.name}</span></div>
					<Link className="PopupVenue-closeBtn" to={'/'} />
				</div>
				<div className="PopupVenue-body">
					<div className="PopupVenue-description">
						<div className="PopupVenue-descriptionText">
							{venueData.description 
								? renderMarkdown(venueData.description) 
								: <p>{uiTexts.noDescription[languageId]}.</p>
							}
						</div>
					</div>
					<div className="PopupVenue-locations">
						{locations.map((location) => {
							const isClosed = checkIfLocationIsClosed(location, venueData.rooms);
							const cityData = citiesData.find((c) => {return c.id === location.cityId;});
							let rooms = [];
							venueData.rooms.forEach((r) => {
								const locationIndex = r.locations.findIndex((l) => {
									return l.locationId === location.id;
								});
								if (locationIndex >= 0) {
									const roomData = JSON.parse(JSON.stringify(r));
									roomData.venueData = venueData;
									roomData.opened = roomData.locations[locationIndex].opened;
									roomData.closed = roomData.locations[locationIndex].closed;
									roomData.rooms = (roomData.locations[locationIndex].rooms 
										? roomData.locations[locationIndex].rooms : 1);
									rooms.push(roomData);
								}
							});
							rooms = sortArrayByProperty(rooms, 'opened', 'DESC');
							return (
								<div key={location.id} className="PopupVenue-location">
									<div className="PopupVenue-locationHeader">
										<div className="PopupVenue-locationTitle">
											<span>{cityData.name[languageId]}</span>
											{isClosed && 
												<span className="red"> - {(uiTexts.closed[languageId]).toLowerCase()}
												</span>}
										</div>
										<div className="PopupVenue-locationAddress">
											<span>{location.address}</span>
										</div>
										<div className="PopupVenue-locationWebsite">
											<a 
												href={venueData.website}
												target="_blank"
												rel="noopener noreferrer"	
											>{website}</a>
										</div>
									</div>
									<div className="PopupVenue-locationRooms">
										<Rooms 
											type={'venue-popup'}
											languageId={languageId}
											deviceInfo={deviceInfo}
											rooms={rooms}
											userData={userData}
										/>
									</div>
								</div>
							);
						})}

					</div>
				</div>	
			</div>
		</div>
	);
};

PopupVenue.propTypes = {
	languageId: PropTypes.string.isRequired,
	deviceInfo: PropTypes.object.isRequired,
	userData: PropTypes.object
};

export default PopupVenue;