const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'royal-escapes',
	name: 'Royal Escapes',
	website: 'https://royalescapes.dk',
	locations: [{
		id: 'copenhagen',
		cityId: 'copenhagen',
		opened: '20210524',
		closed: null,
		address: 'Fredericiagade 30, 1310 København K',
		coordinates: {lat: '55.68549', lng: '12.59160'},
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'the-royal-dungeon',
			name: {da: 'The Royal Dungeon', en: 'The Royal Dungeon'},
			languages: ['da', 'en'],
			minimumAge: 14,
			difficulty: 80,
			locations: [{
				locationId: 'copenhagen',
				opened: '20210606',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-royal-mystery',
			name: {da: 'Det Royale Mysterium', en: 'The Royal Mystery'},
			languages: ['da', 'en'],
			minimumAge: 10,
			difficulty: 60,
			locations: [{
				locationId: 'copenhagen',
				opened: '20210524',
				closed: null,
				rooms: 1
			}]

		}),
		Object.assign({}, roomTemplate, {
			id: 'the-royal-playroom',
			name: {da: 'The Royal Playroom', en: 'The Royal Playroom'},
			languages: ['en'],
			teamSizeMax: 3,
			minimumAge: 10,
			difficulty: 83,
			locations: [{
				locationId: 'copenhagen',
				opened: '20221023',
				closed: null,
				rooms: 1
			}]
		}),
	]
});


export {venueData};