/**
 * Gets the orientation of the screen 
 * @returns {string} orientation
 */
const getOrientation = () => {
	const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
	const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
	const orientation = (width > height ? 'landscape' : 'portrait');
	return orientation;
};

/**
 * Gets the aspect ratio of the screen
 * @returns {number} aspectRatio
 */
const getAspectRatio = () => {
	const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
	const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
	const aspectRatio = parseFloat((width / height).toFixed(2));
	return aspectRatio;
};


export {
	getOrientation,
	getAspectRatio
};