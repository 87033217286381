const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'escape-attic',
	name: 'Escape Attic',
	website: 'https://escapeattic.dk',
	locations: [{
		id: 'odder',
		cityId: 'odder',
		opened: '20180618',
		closed: null,
		address: 'Ballevej 2, 8300 Odder',
		coordinates: {lng: '10.152138', lat: '55.9841'},
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'the-flood',
			name: {da: 'Syndfloden', en: 'The Flood'},
			languages: ['da', 'en', 'de'],
			minutes: 70,
			teamSizeMax: 5,
			minimumAge: 13,
			minimumAgeNoAdult: 18,
			difficulty: 100,
			locations: [{
				locationId: 'odder',
				opened: '20180618',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'zimmer-657',
			name: {da: 'Zimmer 657', en: 'Zimmer 657'},
			teamSizeMax: 5,
			minimumAge: 13,
			minimumAgeNoAdult: 18,
			difficulty: 50,
			locations: [{
				locationId: 'odder',
				opened: '20181215',
				closed: null,
				rooms: 1
			}]
		}),
	]
});


export {venueData};