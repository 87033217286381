const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'eventcamp-ertebjerggaard',
	name: 'EventCamp Ertebjerggaard',
	website: 'https://event-ertebjerggaard.com',
	locations: [{
		id: 'slagelse',
		cityId: 'slagelse',
		opened: '20200219',
		closed: null,
		address: 'Krænkerupvej 14A, 4200 Slagelse',
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'world-war-2',
			name: {da: '2. Verdenskrig', en: 'World War II'},			
			locations: [{
				locationId: 'slagelse',
				opened: '20200219',
				teamSizeMin: 4,
				rooms: 1
			}]
		}),		
		Object.assign({}, roomTemplate, {
			id: 'factory-cafe',
			name: {da: 'Fabrik Cafe', en: 'Factory Cafe'},			
			locations: [{
				locationId: 'slagelse',
				opened: '20200219',
				teamSizeMin: 4,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'leif-mortensens-condo',
			name: {da: 'Leif Mortensens Lejlighed', en: 'Leif Mortensen\'s Condo'},			
			locations: [{
				locationId: 'slagelse',
				opened: '20200219',
				teamSizeMin: 4,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-slaughterhouse',
			name: {da: 'Slagteriet', en: 'The Slaughterhouse'},
			locations: [{
				locationId: 'slagelse',
				opened: '20200219',
				teamSizeMin: 4,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'prison-break',
			name: {da: 'Prison Break', en: 'Prison Break'},
			locations: [{
				locationId: 'slagelse',
				opened: '20200219',
				teamSizeMin: 4,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'chuckys-room',
			name: {da: 'Chucky\'s Room', en: 'Chucky\'s Room'},
			locations: [{
				locationId: 'slagelse',
				opened: '20200219',
				teamSizeMin: 4,
				rooms: 1
			}]
		}),

	]
});

export {venueData};