const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'escape-team',
	name: 'Escape Team',
	website: 'https://www.escapeteam.dk',
	locations: [{
		id: 'aarhus',
		cityId: 'aarhus',
		opened: '20190520',
		closed: '20210301',
		address: 'Hvidtjørnvej 14, 8471 Sabro, Aarhus',
		coordinates: {lat: '56.2066374', lng: '10.0436246'},
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'live-and-let-dive',
			name: {da: 'Live And Let Dive', en: 'Live And Let Dive'},
			teamSizeMin: 3,
			teamSizeMax: 5,
			locations: [{
				locationId: 'aarhus',
				opened: '20190520',
				closed: '20210301',
				rooms: 1
			}]
		})
	]
});


export {venueData};