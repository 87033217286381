const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'getout',
	name: 'GetOut',
	website: 'http://www.getoutnow.dk',
	locations: [{
		id: 'aalborg',
		cityId: 'aalborg',
		opened: '20141203',
		closed: null,
		address: 'Østerågade 25, 2th, 9000 Aalborg',
		coordinates: {lat: 57.049685, lng: 9.922047},
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'alice-out-of-wonderland',
			name: {da: 'Alice ud af Eventyrland', en: 'Alice out of Wonderland'},
			languages: ['da', 'en'],
			teamSizeMax: 5,
			minimumAge: 12,
			tagIds: ['kids'],
			locations: [{
				locationId: 'aalborg',
				opened: '20171016',
				closed: '20200105',
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'corrupt',
			name: {da: 'Korrupt', en: 'Corrupt'},
			languages: ['da', 'en'],
			teamSizeMax: 5,
			minimumAge: 12,
			minimumAgeNoAdult: 18,
			tagIds: ['kids'],
			locations: [{
				locationId: 'aalborg',
				opened: '20181003',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-cabin',
			name: {da: 'The Cabin', en: 'The Cabin'},
			languages: ['da', 'en'],
			teamSizeMax: 5,
			minimumAge: 18,
			locations: [{
				locationId: 'aalborg',
				opened: '20200113',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-pirate',
			name: {da: 'Piraten', en: 'The Pirate'},
			languages: ['da', 'en'],
			teamSizeMax: 5,
			minimumAgeNoAdult: 13,
			difficulty: 199,
			locations: [{
				locationId: 'aalborg',
				opened: '20202117',
				closed: null,
				rooms: 1
			}]
		}),
	]
});


export {venueData};