const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'riddle-room',
	name: 'RiddleRoom',
	website: 'https://riddleroom.dk',
	locations: [{
		id: 'naestved',
		cityId: 'naestved',
		opened: '20170210',
		closed: '20210101',
		address: 'Transportbuen 6, 4700 Næstved',
		coordinates: {lat: '55.251143', lng: '11.790367'},
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'the-childrens-room',
			name: {da: 'Børneværelset', en: 'The Children\'s Room'},
			languages: ['da', 'en'],
			teamSizeMin: 4,
			teamSizeMax: 7,
			minimumAge: 12,
			minimumAgeNoAdult: 15,
			locations: [{
				locationId: 'naestved',
				opened: '20181101',
				closed: '20210101',
				rooms: 2
			}]
		}),
	]
});


export {venueData};