const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'zagadka',
	name: 'Zagadka Escape Games',
	website: 'http://www.zagadka.dk',
	locations: [{
		id: 'aalborg',
		cityId: 'aalborg',
		opened: '20190515',
		closed: '20250309',
		address: 'Vesterbro 64, 9000 Aalborg',
		coordinates: {lat: '57.049270', lng: '9.916970'},
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'stalins-doctors',
			name: {da: 'Stalins Læger', en: 'The Doctor\'s plot'},
			languages: ['da', 'en'],
			teamSizeMin: 2,
			teamSizeMax: 10,
			minimumAge: 13,
			difficulty: 90,
			locations: [{
				locationId: 'aalborg',
				opened: '20220210',
				closed: '20250309',
				rooms: 2
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-djatlov-mystery',
			name: {da: 'Djatlov-mysteriet', en: 'The Djatlov Mystery'},
			languages: ['da', 'en'],
			minimumAge: 14,
			difficulty: 90,
			locations: [{
				locationId: 'aalborg',
				opened: '20190515',
				closed: '20250309',
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-gulag-escape',
			name: {da: 'GULAG-flugten', en: 'The GULAG Escape'},
			languages: ['da', 'en'],
			teamSizeMin: 2,
			teamSizeMax: 6,
			minimumAge: 14,
			difficulty: 50,
			tagIds: ['beginners'],
			locations: [{
				locationId: 'aalborg',
				opened: '20190515',
				closed: '20250309',
				rooms: 1
			}]
		})
	]
});


export {venueData};