const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'goodlock',
	name: 'GoodLock',	
	website: 'http://goodlock.dk',
	locations: [{
		id: 'silkeborg',
		cityId: 'silkeborg',
		opened: '20200628',
		closed: null,
		address: 'Baunehøjvej 9a, 8600 Silkeborg',
		coordinates: {lat: '56.186086', lng: '9.578635'},
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'the-gold-heist',
			name: {da: 'Guldkuppet', en: 'The Gold Heist'},
			minimumAge: 9,
			minimumAgeNoAdult: 14,
			difficulty: 70,
			tagIds: ['beginners'],
			locations: [{
				locationId: 'silkeborg',
				opened: '20210521',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-terrorist',
			name: {da: 'Terroristen', en: 'The Terrorist'},
			teamSizeMax: 5,
			minimumAge: 9,
			minimumAgeNoAdult: 14,
			difficulty: 90,
			locations: [{
				locationId: 'silkeborg',
				opened: '20200628',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'virus',
			name: {da: 'Virus', en: 'Virus'},
			languages: ['da'],
			teamSizeMax: 5,
			minimumAge: 9,
			minimumAgeNoAdult: 14,
			difficulty: 80,
			locations: [{
				locationId: 'silkeborg',
				opened: '20200628',
				closed: null,
				rooms: 1
			}]
		}),
	]
});


export {venueData};