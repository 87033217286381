const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'rubilees-escape-house',
	name: 'Rubilee\'s Escape House',	
	website: 'https://www.escape-house.dk',
	locations: [{
		id: 'soenderborg',
		cityId: 'soenderborg',
		opened: '20160601',
		closed: null,
		address: 'Rådhustorvet 5, 1. sal, 6400 Sønderborg',
		coordinates: {lat: '54.90911', lng: '9.789425'},
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: '1864-spy',
			name: {da: '1864 Spion - Slaget om Als', en: '1864 Spy - The Battle of Als'},
			languages: ['da', 'en'],
			teamSizeMax: 5,
			minimumAge: 7,
			locations: [{
				locationId: 'soenderborg',
				opened: '20190211',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'academy-of-magic',
			name: {da: 'Rubilee\'s Academy of Magic', en: 'RubiLee\'s Academy of Magic'},
			languages: ['da', 'en'],
			teamSizeMax: 8,
			minimumAge: 7,
			locations: [{
				locationId: 'soenderborg',
				opened: '20181013',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-secret-brotherhood',
			name: {da: 'The Secret Brotherhood', en: 'The Secret Brotherhood'},
			languages: ['da', 'en'],
			teamSizeMax: 8,
			minimumAge: 7,
			locations: [{
				locationId: 'soenderborg',
				opened: '20230201',
				closed: null,
				rooms: 1
			}]
		}),
	]
});


export {venueData};