const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'escape-room-varde',
	name: 'Escape Room Varde',
	website: 'http://www.escaperoomvarde.dk',
	locations: [{
		id: 'varde',
		cityId: 'varde',
		opened: '20181213',
		closed: '20200131',
		address: 'Stilbjergvej 53, 6800 Varde',
		coordinates: {lng: '8.468255', lat: '55.661923'},
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'escape-room-varde',
			name: {da: 'Escape Room Varde', en: 'Escape Room Varde'},
			languages: ['da'],
			teamSizeMax: 5,
			minimumAge: 10,
			minimumAgeNoAdult: 14,
			tagIds: ['kids', 'beginners'],
			locations: [{
				locationId: 'varde',
				opened: '20181213',
				closed: '20200131',
				rooms: 1,
			}]
		})
	]
});


export {venueData};