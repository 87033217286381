import {useState, useEffect} from 'react';
import {Routes, Route} from 'react-router-dom';
import {getOrientation, getAspectRatio} from 'helpers/device-helper';
import firebaseInit from 'firebase-init';
import Auth from './auth/auth';
import Header from 'components/ui/header/header';
import RoomsOrVenues from './rooms-or-venues/rooms-or-venues';
import PopupVenue from './popup-venue/popup-venue';
import PopupAbout from './popup-about/popup-about';
import PopupMap from './popup-map/popup-map';
import PopupLogin from './popup-login/popup-login';
import './app.scss';

const App = () => {
	/* Device info */
	const [deviceInfo, setDeviceInfo] = useState(() => {
		return {orientation: getOrientation(), aspectRatio: getAspectRatio()};
	});

	/* Language */
	const [languageId, setLanguageId] = useState('da');

	/* Is logged in flag */
	const [isLoggedIn, setIsLoggedIn] = useState(false);

	/* User data */
	const [userData, setUserData] = useState(null);

	/**
	 * User resized window, update orientation and aspect ratio
	 */
	const handleWindowResize = () => {
		let newDeviceInfo = JSON.parse(JSON.stringify(deviceInfo));
		newDeviceInfo.orientation = getOrientation();
		newDeviceInfo.aspectRatio = getAspectRatio();
		setDeviceInfo(newDeviceInfo);
	};

	/**
	 * Component mounted
	 */
	useEffect(() => {
		/* Initialize firebase */
		firebaseInit();

		/* Add event listener for window size */
		if (document.addEventListener) window.addEventListener('resize', handleWindowResize, false);

		/* Component will ummount */
		return () => {
			if (document.removeEventListener) window.removeEventListener('resize', handleWindowResize, false);
		};
	});

	return (
		<div className={'App ' + deviceInfo.orientation}>
			<Auth setIsLoggedIn={setIsLoggedIn} setUserData={setUserData}>
				<Header 
					isLoggedIn={isLoggedIn}
					languageId={languageId} 
					deviceInfo={deviceInfo} 
					setLanguageId={setLanguageId} 
				/>
				<RoomsOrVenues 
					isLoggedIn={isLoggedIn}
					languageId={languageId} 
					deviceInfo={deviceInfo} 
					userData={userData}
				/>
				<Routes>
					<Route 
						path="/about" 
						element={
							<PopupAbout 
								languageId={languageId} 
								deviceInfo={deviceInfo} 
							/>
						} 
					/>
					<Route 
						path="/map" 
						element={
							<PopupMap 
								languageId={languageId} 
								deviceInfo={deviceInfo} 
							/>
						} 
					/>
					<Route 
						path="/login" 
						element={
							<PopupLogin 
								isLoggedIn={isLoggedIn}
								languageId={languageId} 
								deviceInfo={deviceInfo} 
							/>
						}
					/>
					<Route 
						path="/:venueId" 
						element={
							<PopupVenue 
								isLoggedIn={isLoggedIn}
								languageId={languageId} 
								deviceInfo={deviceInfo} 
								userData={userData}
							/>
						}
					/>
					<Route path="*" element={null} />
				</Routes>
			</Auth>
		</div>
	);
};

export default App;