import appConfig from 'config/app.config';
import {sortArrayByProperty} from './array-helpers';
import {
	checkIfRoomIsOpened, 
	checkIfRoomIsClosed, 
	checkIfRoomIsInCity, 
	checkIfRoomIsInRegion,
	getOldestOpenedDate,
	getNewestOpenedDate
} from './rooms-helper';
import {
	getVenueFirstOpenedDate,
	getVenueLastOpenedDate,
	checkIfVenueIsOpened, 
	checkIfVenueIsClosed, 
	checkIfVenueIsInCity,
	checkIfVenueIsInRegion
} from './venues-helper';

/**
 * Update filter (add/remove item)
 * @param {string} itemId 
 * @param {array} itemArray 
 * @returns 
 */
const updateFilter = (itemId, itemArray) => {
	const newItemArray = JSON.parse(JSON.stringify(itemArray));
	const itemIndex = newItemArray.indexOf(itemId);
	if (itemIndex < 0) {
		newItemArray.push(itemId);
	} else {
		newItemArray.splice(itemIndex, 1);
	}

	return newItemArray;
};


/**
 * Get filtered rooms
 * @returns {array}
 */
const getFilteredRooms = (
	rooms, 
	showClosed, 
	showFuture,
	hideCompleted,
	regionId,
	cityId,
	excludedLanguageIds,
	userData
) => {

	/* Prepare rooms to be filtered */
	let filteredRooms = JSON.parse(JSON.stringify(rooms));

	/* Exclude future rooms */
	if (!showFuture) {
		filteredRooms = filteredRooms.filter((room) => {
			return checkIfRoomIsOpened(room);
		});
	}

	/* Exclude closed rooms */
	if (!showClosed) {
		filteredRooms = filteredRooms.filter((room) => {
			return !checkIfRoomIsClosed(room);
		});
	}

	/* Exclude completed rooms */
	if (hideCompleted) {
		/* Get completed rooms */
		const completedRooms = (userData && userData.completedRooms ? userData.completedRooms : []);
		filteredRooms = filteredRooms.filter((room) => {
			return !completedRooms.some((r) => {
				return (r.venueId === room.venueData.id && r.roomId === room.id);
			});
		});
	}

	/* Filter by region / city */
	if (cityId !== 'all-cities') {
		/* Filter by city */
		filteredRooms = filteredRooms.filter((room) => {
			return checkIfRoomIsInCity(room, cityId);
		});
	} else if (regionId !== 'denmark') {
		/* Filter by region */
		filteredRooms = filteredRooms.filter((room) => {
			return checkIfRoomIsInRegion(room, regionId);
		});
	}

	/* Filter room by language */
	if (excludedLanguageIds.length > 0) {
		filteredRooms = filteredRooms.filter((room) => {
			let hasSelectedLanguages = false;
			room.languages.forEach((languageId) => {
				if (excludedLanguageIds.indexOf(languageId) < 0) hasSelectedLanguages = true;
			});
			return hasSelectedLanguages;
		});
	}

	return filteredRooms;
};

const getFilteredVenues = (venues, showClosed, showFuture, regionId, cityId) => {
	/* Prepare venues to be filtered */
	let filteredVenues = JSON.parse(JSON.stringify(venues));

	/* Exclude future venues */
	if (!showFuture) {
		filteredVenues = filteredVenues.filter((venue) => {
			return checkIfVenueIsOpened(venue);
		});
	}
	

	/* Exclude closed rooms */
	if (!showClosed) {
		filteredVenues = filteredVenues.filter((venue) => {
			return !checkIfVenueIsClosed(venue);
		});
	}

	/* Filter by region / city */
	if (cityId !== 'all-cities') {
		/* Filter by city */
		filteredVenues = filteredVenues.filter((venue) => {
			return checkIfVenueIsInCity(venue, cityId);
		});
	} else if (regionId !== 'denmark') {
		/* Filter by region */
		filteredVenues = filteredVenues.filter((venue) => {
			return checkIfVenueIsInRegion(venue, regionId);
		});
	}

	return filteredVenues;
};

/**
 * Get sorted rooms
 * @returns {array}
 */
const getSortedRooms = (rooms, languageId, sortBy, sortDir) => {
	if (rooms.length <= 1) return rooms;

	let sortedRooms = JSON.parse(JSON.stringify(rooms));
	let sortByA = sortBy;
	let sortDirA = sortDir;
	let sortByB = null;
	let sortDirB = null;
	let sortByC = null;
	let sortDirC = null;

	sortedRooms.forEach((room) => {
		room.sortableVenueId = room.venueData.id;

		room.sortableAge = room.minimumAge !== null
			? room.minimumAge
			: appConfig.defaultMinAge;

		room.sortableRating = room.rating
			?	(100. / appConfig.maxRating) * room.rating.stars
			: 0;

		room.sortableRoomName = room.name[languageId];

		
		room.sortableLastOpened = getOldestOpenedDate(room);
		room.sortableFirstOpened = getNewestOpenedDate(room);
	});

	if (sortBy !== 'sortableVenueId') {
		sortByB = 'sortableVenueId';
		sortDirB = 'ASC';
	}

	if (sortBy === 'sortableTeamSize') {
		sortDirB = sortDir;
		sortByC = 'sortableVenueId';
		sortDirC = 'ASC';
		if (sortDir === 'ASC') {
			sortByA = 'teamSizeMin';
			sortByB = 'teamSizeMax';
		} else {
			sortByA = 'teamSizeMax';
			sortByB = 'teamSizeMin';
		}
	}

	if (sortBy === 'sortableOpened') {
		if (sortDir === 'ASC') {
			sortByA = 'sortableFirstOpened'; // oldest first
		} else {
			sortByA = 'sortableLastOpened';
		}
	}


	sortedRooms = sortArrayByProperty(
		sortedRooms, 
		sortByA, 
		sortDirA, 
		sortByB, 
		sortDirB,
		sortByC,
		sortDirC
	);
	
	return sortedRooms;
};

/**
 * Get sorted venues
 * @param {array} venues 
 * @param {string} languageId 
 * @param {string} sortBy 
 * @param {string} sortDir 
 * @returns 
 */
const getSortedVenues = (venues, languageId, sortBy, sortDir) => {
	if (venues.length <= 1) return venues;

	let sortedVenues = JSON.parse(JSON.stringify(venues));
	let sortByA = sortBy;
	let sortDirA = sortDir;
	let sortByB = null;
	let sortDirB = null;

	sortedVenues.forEach((venue) => {
		venue.sortableVenueName = venue.name;
		
		venue.sortableLastOpened = getVenueLastOpenedDate(venue);
		venue.sortableFirstOpened = getVenueFirstOpenedDate(venue);
	});

	if (sortBy !== 'sortableVenueName') {
		sortByB = 'sortableVenueName';
		sortDirB = 'ASC';
	}

	if (sortBy === 'sortableOpened') {
		if (sortDir === 'ASC') {
			sortByA = 'sortableFirstOpened'; // oldest first
		} else {
			sortByA = 'sortableLastOpened';
		}
	}

	sortedVenues = sortArrayByProperty(
		sortedVenues, 
		sortByA, 
		sortDirA, 
		sortByB, 
		sortDirB,
	);
	
	return sortedVenues;
};


export {updateFilter, getFilteredRooms, getSortedRooms, getFilteredVenues, getSortedVenues};