const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'questbox',
	name: 'QuestBox',	
	website: 'http://questbox.dk',
	locations: [{
		id: 'skive',
		cityId: 'skive',
		opened: '20160708',
		address: 'Savværksvej 14, 7870 Skive',
		coordinates: {lng: '9.099979', lat: '56.771615'},
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'questbox',
			name: {da: 'QuestBox', en: 'QuestBox'},
			languages: ['da', 'en'],
			teamSizeMax: 5,
			minimumAge: 16,
			locations: [{
				locationId: 'skive',
				opened: '20160708',
				closed: '20240701',
				rooms: 1
			}]
		}),
	]
});


export {venueData};