import React from 'react';
import PropTypes from 'prop-types';
import {languagesData} from 'data/languages-data';
import { uiTexts } from 'data/ui-texts';
import './languages-panel.scss';

const LanguagesPanel = ({languageId, excludedLanguages, toggleLanguage}) => {
	return (
		<div className="LanguagesPanel">
			<div className="LanguagesPanel-title">{uiTexts.languages[languageId]}:</div>
			<div className="LanguagesPanel-container">
				{languagesData.map((languageData) => {
					const isSelected = excludedLanguages.indexOf(languageData.id) < 0;
					const className = 'LanguagesPanel-icon ' + languageData.id + (isSelected ? ' selected' : '');
					return (
						<div 
							key={languageData.id} 
							className={className}
							onClick={() => {toggleLanguage(languageData.id);}}
						/>
					);
				})}
			</div>
		</div>
	);
};

LanguagesPanel.propTypes = {
	languageId: PropTypes.string.isRequired,
	excludedLanguages: PropTypes.array.isRequired,
	toggleLanguage: PropTypes.func.isRequired
};



export default LanguagesPanel;