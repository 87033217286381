import React from 'react';
import PropTypes from 'prop-types';
import {uiTexts} from 'data/ui-texts';
import './status-panel.scss';

const StatusPanel = (props) => {
	/* Props */
	const {
		isLoggedIn,
		languageId, 
		showClosed, 
		showFuture, 
		hideCompleted, 
		setShowClosed, 
		setShowFuture, 
		setHideCompleted
	} = props;

	return (
		<div className="StatusPanel">
			<div className="StatusPanel-future">
				<div className="StatusPanel-title">{uiTexts.includeComing[languageId]}:</div>
				<div className="StatusPanel-container">
					<div 
						className={'StatusPanel-toggleBtn' + (showFuture ? ' selected' : '')}
						onClick={() => {setShowFuture(!showFuture);}}
					/>
				</div>
			</div>
			<div className="StatusPanel-closed">
				<div className="StatusPanel-title">{uiTexts.includeClosed[languageId]}:</div>
				<div className="StatusPanel-container">
					<div 
						className={'StatusPanel-toggleBtn' + (showClosed ? ' selected' : '')}
						onClick={() => {setShowClosed(!showClosed);}}
					/>
				</div>
			</div>
			{isLoggedIn && <div className="StatusPanel-completed">
				<div className="StatusPanel-title">{uiTexts.hideCompleted[languageId]}:</div>
				<div className="StatusPanel-container">
					<div 
						className={'StatusPanel-toggleBtn' + (hideCompleted ? ' selected' : '')}
						onClick={() => {setHideCompleted(!hideCompleted);}}
					/>
				</div>
			</div>}
		</div>
	);
};

StatusPanel.propTypes = {
	isLoggedIn: PropTypes.bool.isRequired,
	languageId: PropTypes.string.isRequired,
	showClosed: PropTypes.bool.isRequired,
	showFuture: PropTypes.bool.isRequired,
	hideCompleted: PropTypes.bool.isRequired,
	setShowClosed: PropTypes.func.isRequired,
	setShowFuture: PropTypes.func.isRequired,
	setHideCompleted: PropTypes.func.isRequired,
};



export default StatusPanel;