const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'koldkrigsmuseum-stevnsfort',
	name: 'Koldkrigsmuseum Stevnsfort',
	website: 'https://www.oesm.dk/koldkrigsmuseum-stevnsfort/',
	locations: [{
		id: 'roedvig',
		cityId: 'roedvig',
		opened: '20190801',
		address: 'Korsnæbsvej 60, 4673 Rødvig',
		coordinates: {lat: '55.264569', lng: '12.409658'},
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'escape-room-bunker',
			name: {da: 'Escape Room Bunker', en: 'Escape Room Bunker'},			
			languages: ['da'],
			teamSizeMin: 9,
			teamSizeMax: 15,
			minimumAge: 13,
			tagIds: ['educational'],
			locations: [{
				locationId: 'roedvig',
				opened: '20190812',
				closed: null,
				rooms: 1
			}]
		}),
	]
});


export {venueData};