const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'wayout-copenhagen',
	name: 'WayOut Copenhagen',
	website: 'http://www.wayoutcph.dk',
	locations: [{
		id: 'copenhagen',
		cityId: 'copenhagen',
		opened: '20150000',
		closed: '20210101',
		address: 'Fredericiagade 30, 1310 København',
		coordinates: {lng: '12.589681', lat: '55.686105'},
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'casino',
			name: {da: 'Casino', en: 'Casino'},
			languages: ['da', 'en'],
			teamSizeMax: 5,
			difficulty: 50,
			tagIds: ['kids', 'beginners'],
			locations: [{
				locationId: 'copenhagen',
				opened: '20150101',
				closed: '20210101',
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'mr-greys-room',
			name: {da: 'Mr. Grey\'s Room', en: 'Mr. Grey\'s Room'},
			languages: ['en'],
			teamSizeMax: 2,
			minimumAge: 18,
			difficulty: 66.67,
			locations: [{
				locationId: 'copenhagen',
				opened: '20150101',
				closed: '20210101',
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'taken',
			name: {da: 'Taken', en: 'Taken'},
			languages: ['en'],
			difficulty: 100,
			locations: [{
				locationId: 'copenhagen',
				opened: '20150101',
				closed: '20210101',
				rooms: 1
			}]
		}),
	]
});


export {venueData};