const roomSortablesData = [
	{
		id: 'sortableOpened',
		title: {
			en: 'Date opened',
			da: 'Åbningsdato'
		},
		sortDirection: 'DESC'
	},
	{
		id: 'sortableVenueId',
		title: {
			en: 'Venue name',
			da: 'Firmanavn'
		},
		sortDirection: 'ASC'
	},
	{
		id: 'sortableRoomName',
		title: {
			en: 'Room name',
			da: 'Rummets navn'
		},
		sortDirection: 'ASC'
	},
	{
		id: 'sortableTeamSize',
		title: {
			en: 'Team size',
			da: 'Holdstørrelse'
		},
		sortDirection: 'ASC'
	},
	{
		id: 'sortableAge',
		title: {
			en: 'Age',
			da: 'Alder'
		},
		sortDirection: 'ASC'
	},
];

export {roomSortablesData};