const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'brain-escape',
	name: 'Brain Escape',
	website: 'http://www.brainescape.dk',
	locations: [{
		id: 'aarhus',
		cityId: 'aarhus',
		opened: '20181020',
		closed: '20231231',
		address: 'Mejlgade 27-29 kl., 8000 Aarhus',
		coordinates: {lat: '56.15875', lng: '10.21194'},
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'legio-santana',
			name: {da: 'Legio Santana', en: 'Hypnotisørens hjerne'},
			languages: ['da', 'en'],
			teamSizeMin: 3,
			teamSizeMax: 10,
			minimumAgeNoAdult: 12,
			locations: [{
				locationId: 'aarhus',
				opened: '20181020',
				closed: '20231231',
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'pandoras-dream',
			name: {da: 'Pandoras drøm', en: 'Pandora\'s dream'},
			languages: ['da', 'en'],
			teamSizeMax: 5,
			minimumAgeNoAdult: 12,
			locations: [{
				locationId: 'aarhus',
				opened: '20210521',
				closed: '20231231',
				rooms: 1
			}]
		})
	]
});


export {venueData};