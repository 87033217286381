const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'escape-games',
	name: 'Escape Games',	
	website: 'http://www.escapegames.dk',
	locations: [{
		id: 'skanderborg',
		cityId: 'skanderborg',
		opened: '20170701',
		closed: null,
		address: 'Industrivej 3 A, 8660 Skanderborg',
		coordinates: {lat: '56.067120', lng: '9.996971'},
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'ednas-condo',
			name: {da: 'Ednas Lejlighed', en: 'Edna\'s Condo'},
			languages: ['da', 'en'],
			minimumAge: 7,
			minimumAgeNoAdult: 12,
			difficulty: 90,
			locations: [{
				locationId: 'skanderborg',
				opened: '20170701',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'find-felix',
			name: {da: 'Find Felix', en: 'Find Felix'},
			languages: ['da', 'en'],
			minutes: 45,
			teamSizeMax: 5,
			minimumAge: 5,
			difficulty: 40,
			tagIds: ['kids', 'beginners'],
			locations: [{
				locationId: 'skanderborg',
				opened: '20170701',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'pirate-island',
			name: {da: 'Piratøen', en: 'Pirate Island'},
			languages: ['da', 'en'],
			minimumAge: 6,
			minimumAgeNoAdult: 10,
			difficulty: 70,
			tagIds: ['kids', 'beginners'],
			locations: [{
				locationId: 'skanderborg',
				opened: '20170701',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'santas-time-machine',
			name: {da: 'Julemandens Tidsmaskine', en: 'Santa\'s Time Machine'},
			languages: ['da'],
			teamSizeMax: 5,
			minimumAge: 6,
			minimumAgeNoAdult: 10,
			difficulty: 70,
			tagIds: ['kids', 'beginners'],
			locations: [{
				locationId: 'skanderborg',
				opened: '20170701',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-hospital',
			name: {da: 'Hospitalet', en: 'The Hospital'
			},
			teamSizeMax: 4,
			minimumAge: 12,
			difficulty: 80,
			tagIds: ['kids', 'beginners', 'battle-room'],
			locations: [{
				locationId: 'skanderborg',
				opened: '20170701',
				closed: true,
				rooms: 2
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-lab',
			name: {da: 'The Lab', en: 'The Lab'},
			languages: ['da', 'en'],
			minimumAge: 7,
			minimumAgeNoAdult: 12,
			difficulty: 80,
			tagIds: ['kids', 'beginners'],
			locations: [{
				locationId: 'skanderborg',
				opened: '20170701',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-viking-expedition',
			name: {da: 'Vikingetogtet', en: 'The Viking Expedition'},
			minimumAge: 7,
			minimumAgeNoAdult: 12,
			difficulty: 60,
			tagIds: ['kids', 'beginners'],
			locations: [{
				locationId: 'skanderborg',
				opened: '20200624',
				closed: null,
				rooms: 1
			}]
		}),
	]
});


export {venueData};