const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'penguin-escape',
	name: 'Penguin Escape',	
	website: 'https://www.penguinescape.dk',
	locations: [{
		id: 'birkeroed',
		cityId: 'birkeroed',
		opened: '20200713',
		closed: null,
		address: 'Hovedgaden 24, 3460 Birkerød',
		coordinates: {lng: '12.43215', lat: '55.84201'},
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'the-poachers-cabin',
			name: {da: 'Krybskytternes Hytte', en: 'The Poachers\' Cabin'},			
			languages: ['da', 'en'],
			teamSizeMax: 8,
			minimumAge: 8,
			minimumAgeNoAdult: 14,
			minutes: 75,
			locations: [{
				locationId: 'birkeroed',
				opened: '20200713',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-witchs-kitchen',
			name: {da: 'Heksens Køkken', en: 'The Witch\'s Kitchen'},
			languages: ['da', 'en'],
			teamSizeMax: 8,
			minimumAge: 8,
			minimumAgeNoAdult: 14,
			minutes: 75,
			locations: [{
				locationId: 'birkeroed',
				opened: '20210613',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-red-ruby',
			name: {da: 'Den Røde Rubin', en: 'The Red Ruby'},
			languages: ['da'],
			teamSizeMax: 8,
			minimumAge: 8,
			minimumAgeNoAdult: 14,
			minutes: 75,
			locations: [{
				locationId: 'birkeroed',
				opened: '99999999',
				closed: null,
				rooms: 1
			}]
		})
	]
});


export {venueData};