
import {getCurrentDate} from './date-helper';
import {getFilteredVenues, getSortedVenues} from './filter-helpers';
import {citiesData} from 'data/cities-data';
import {venuesAndRooms} from 'data/venues-and-rooms-data';

const getFilteredAndSortedVenues = (showClosed, showFuture, languageId, regionId, cityId, sortBy, sortDir) => {
	/* Filter venues */
	const filteredVenues = getFilteredVenues(venuesAndRooms, showClosed, showFuture, regionId, cityId);

	/* Sort venues */
	const sortedVenues = getSortedVenues(filteredVenues, languageId, sortBy, sortDir);
	return sortedVenues;
};

const getVenueFirstOpenedDate = (venue) => {
	let dateStr = null;
	venue.locations.forEach((location) => {
		if (location.opened) {
			if (!dateStr || parseInt(location.opened) <= parseInt(dateStr)) {
				dateStr = location.opened;
			}
		}
	});
	return dateStr;
};

const getVenueLastOpenedDate = (venue) => {
	let dateStr = null;
	venue.locations.forEach((location) => {
		if (location.opened) {
			if (!dateStr || parseInt(location.opened) >= parseInt(dateStr)) {
				dateStr = location.opened;
			}
		}
	});
	return dateStr;
};


/**
 * Check if venue is opened 
 * @param {object} venue 
 */
const checkIfVenueIsOpened = (venue) => {
	/* Get current date */
	const dateStr = getCurrentDate();

	/* Check if one or more venue locations are opened */
	return venue.locations.some((location) => {
		return (location.opened && parseInt(location.opened) <= parseInt(dateStr));
	});
};

/**
 * Check if venue is closed
 * @param {object} venue 
 * @returns 
 */
const checkIfVenueIsClosed = (venue) => {
	/* Get current date */
	const dateStr = getCurrentDate();

	/* Get closed locations */
	const closedLocations = venue.locations.filter((location) => {
		return (
			location.closed === true || 
			(location.closed && location.closed.length === 8 && parseInt(location.closed) <= parseInt(dateStr))
		);
	});

	/* Check if venue is closed */
	const isClosed = (closedLocations.length === venue.locations.length);

	return isClosed;
};

const checkIfVenueIsInCity = (venue, cityId) => {
	return venue.locations.some((location) => {
		return location.cityId === cityId;
	});
};

/**
 * Check if venue is in region
 * @param {object} venue
 * @param {string} regionId 
 * @returns 
 */
const checkIfVenueIsInRegion = (venue, regionId) => {
	return venue.locations.some((location) => {
		
		const cityData = citiesData.find((city) => {return city.id === location.cityId;});
		return (cityData && cityData.regionId === regionId ? true : false);
	});
};

const checkIfLocationIsClosed = (location, rooms) => {
	let isClosed = (location.hasOwnProperty('closed') && location.closed !== null);
	// if (!isClosed) {
	// 	if ((rooms.filter((r) => {
	// 		return r.locations.some((l) => {return l.locationId === location.id;});
	// 	})).every((r) => {
	// 		return r.closed !== null;
	// 	})) {
	// 		isClosed = true;
	// 	}
	// }
	return isClosed;
};


export {
	getVenueFirstOpenedDate,
	getVenueLastOpenedDate,
	getFilteredAndSortedVenues,
	checkIfVenueIsOpened,
	checkIfVenueIsClosed,
	checkIfVenueIsInCity,
	checkIfVenueIsInRegion,
	checkIfLocationIsClosed
};