const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'ballerup-gokart-and-event-center',
	name: 'Ballerup Gokart- & Eventcenter',
	website: 'https://ballerupeventcenter.dk',
	locations: [{
		id: 'ballerup',
		cityId: 'ballerup',
		opened: '20060901',
		closed: null,
		address: 'Tempovej 35, 2750 Ballerup',
		coordinates: {lng: '12.374928', lat: '55.723705'},
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'the-kiosk',
			name: {da: 'Kiosken', en: 'The Kiosk'},
			minutes: 75,
			teamSizeMin: 4,
			locations: [{
				locationId: 'ballerup',
				opened: '20220906',
				closed: null
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'williams-dilemma',
			name: {da: 'Williams dilemma', en: 'William\'s dilemma'},
			teamSizeMin: 4,
			teamSizeMax: 8,
			locations: [{
				locationId: 'ballerup',
				opened: '20210601',
				closed: null
			}]
		})
	]
});


export {venueData};