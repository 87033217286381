const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'escapee',
	name: 'Escapee',	
	website: 'http://www.escapee.dk',
	locations: [{
		id: 'hilleroed',
		cityId: 'hilleroed',
		opened: '20190308',
		closed: null,
		address: ['Møllestræde 3A, 3400 Hillerød'],
		coordinates: {lat: '55.931063', lng: '12.298616'},
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'area-51',
			name: {da: 'Area 51', en: 'Area 51'},
			teamSizeMin: 4,
			teamSizeMax: 9,
			minimumAge: 12,
			minimumAgeNoAdult: 15,
			difficulty: 83.33,
			locations: [{
				locationId: 'hilleroed',
				opened: '20190510',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'draculas-chamber',
			name: {da: 'Draculas Kammer', en: 'Dracula\'s Chamber'},
			languages: ['da', 'en'],
			minimumAge: 12,
			minimumAgeNoAdult: 15,
			difficulty: 66.67,
			locations: [{
				locationId: 'hilleroed',
				opened: '20190308',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'merlin',
			name: {da: 'Merlin', en: 'Merlin'},
			languages: ['da', 'en'],
			minimumAge: 10,
			difficulty: 67,
			locations: [{
				locationId: 'hilleroed',
				opened: '20220212',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-cursed-treasure',
			name: {da: 'Den Forbandede Skat', en: 'The Cursed Treasure'},
			languages: ['da', 'en'],
			teamSizeMin: 4,
			teamSizeMax: 7,
			minimumAge: 10,
			minimumAgeNoAdult: 15,
			difficulty: 67.67,
			locations: [{
				locationId: 'hilleroed',
				opened: '20191114',
				closed: true,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-orphanage',
			name: {da: 'Børnehjemmet Ambrosius', en: 'Ambrosious Orphanage'},
			minutes: 75,
			teamSizeMax: 7,
			minimumAge: 15,
			difficulty: 83.33,
			locations: [{
				locationId: 'hilleroed',
				opened: '20210601',
				closed: null,
				rooms: 1
			}]
		}),
	]
});


export {venueData};