const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'down-the-rabbit-hole',
	name: 'Down the Rabbit Hole',
	website: 'http://www.downtherabbithole.dk',
	locations: [{
		id: 'copenhagen',
		cityId: 'copenhagen',
		opened: '20180915',
		closed: '20200301',
		address: 'Vesterbrogade 124B 2.th., 1620 København',
		coordinates: {lat: '55.67144', lng: '12.54304'},
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'korowai-a-professors-tale',
			name: {da: 'Korowai - A Professor\'s Tale', en: 'Korowai - A Professor\'s Tale'},
			languages: ['da'],
			teamSizeMin: 3,
			minimumAge: 12,
			difficulty: 75,
			tagIds: ['battle-room'],
			locations: [{
				locationId: 'copenhagen',
				opened: '20180915',
				closed: '20200301',
				rooms: 2
			}]
		}),
	]
});


export {venueData};