const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'play-time',
	name: 'Play-Time',
	website: 'http://www.play-time.dk',
	locations: [{
		id: 'ulfborg',
		cityId: 'ulfborg',
		opened: '20180101',
		closed: null,
		address: 'Lystlundvej 1, 6990 Ulfborg',
		coordinates: {lat: '56.296751', lng: '8.369715'},
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'escape-room-play-time',
			name: {da: 'Escape Room', en: 'Escape Room'},
			languages: ['da', 'en', 'de'],
			teamSizeMax: 5,
			minimumAgeNoAdult: 15,
			locations: [{
				locationId: 'ulfborg',
				opened: '20180101',
				closed: null,
				rooms: 1
			}]
		})
	]
});


export {venueData};