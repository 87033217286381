let env = process.env.REACT_APP_ENV
	? process.env.REACT_APP_ENV
	: process.env.NODE_ENV;

if (!env) {
	console.error('ENV: ', env);
	env = 'development';
}

let gameUrl = 'localhost:8080';
let apiURL = 'http://localhost:8080/api/';
if (env !== 'development') {
	gameUrl = 'escapades.web.app';
	apiURL = 'https://escapades.web.app/api/';
}

const appConfig = {
	env: env,
	name: 'escapades',
	apiURL: apiURL,
	gameUrl: gameUrl,
	defaultLanguage: 'en',
	defaultMinAge: 18,
	maxRating: 5
};

export default appConfig;
