const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'secret-games',
	name: 'Secret Games',
	website: 'http://www.secretgames.dk',
	locations: [{
		id: 'aarhus',
		cityId: 'aarhus',
		opened: '20150501',
		closed: null,
		address: 'Marselis Boulevard 125 kl., 8000 Aarhus',
		coordinates: {lat: '56.139349', lng: '10.187981'},
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'room-of-magic',
			name: {da: 'Room of Magic', en: 'Room of Magic'},
			languages: ['da', 'en'],
			minimumAge: 10,
			minimumAgeNoAdult: 15,
			difficulty: 70,
			tagIds: ['kids'],
			locations: [{
				locationId: 'aarhus',
				opened: '20151005',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'secret-casino',
			name: {da: 'Secret Casino', en: 'Secret Casino'},
			languages: ['da', 'en'],
			minimumAge: 10,
			minimumAgeNoAdult: 15,
			difficulty: 50,
			tagIds: ['kids', 'beginners'],
			locations: [{
				locationId: 'aarhus',
				opened: '20150501',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'vampire-hunters',
			name: {da: 'Vampire Hunters', en: 'Vampire Hunters'},
			languages: ['da', 'en'],
			minimumAge: 10,
			minimumAgeNoAdult: 15,
			difficulty: 50,
			locations: [{
				locationId: 'aarhus',
				opened: '20170925',
				closed: null,
				rooms: 2
			}]
		})
	]
});


export {venueData};