const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'brain-game-cph',
	name: 'Brain Game CPH',
	website: 'http://www.braingamecph.dk',
	locations: [{
		id: 'copenhagen',
		cityId: 'copenhagen',
		opened: '20161201',
		closed: '20200101',
		address: 'Gothersgade 35, 1. sal, 1123 København',
		coordinates: {lng: '12.582156', lat: '55.682182'},
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'pablo-escobar',
			name: {da: 'Pablo Escobar', en: 'Pablo Escobar'},
			languages: ['da', 'en'],
			difficulty: 80,
			locations: [{
				locationId: 'copenhagen',
				opened: '20161201',
				closed: '20200101',
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'seven',
			name: {da: 'Seven', en: 'Seven'},
			languages: ['da', 'en'],
			difficulty: 70,
			locations: [{
				locationId: 'copenhagen',
				opened: '20161201',
				closed: '20200101',
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-last-passenger',
			name: {da: 'Den Sidste Passager', en: 'The Last Passenger'},
			languages: ['da', 'en'],
			difficulty: 80,
			locations: [{
				locationId: 'copenhagen',
				opened: '20161201',
				closed: '20200101',
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-ring',
			name: {da: 'The Ring', en: 'The Ring'},
			languages: ['da', 'en'],
			difficulty: 95,
			tagIds: ['live-actors'],
			locations: [{
				locationId: 'copenhagen',
				opened: '20161201',
				closed: '20200101',
				rooms: 1
			}]
		}),
	]
});


export {venueData};