const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'escape-room-tarm',
	name: 'Escape Room Tarm',	
	website: 'https://www.facebook.com/Lundenaes/',
	locations: [{
		id: 'tarm',
		cityId: 'tarm',
		opened: '20180723',
		address: 'Kirkegade 1, 6880 Tarm',
		coordinates: {lat: '55.907', lng: '8.521601'},
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'the-dairy',
			name: {da: 'Mejeriet', en: 'Mejeriet'},
			languages: ['da', 'en'],
			locations: [{
				locationId: 'tarm',
				opened: '20210727',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-mad-artist',
			name: {da: 'Den Gale Kunstner', en: 'The Mad Artist'},
			languages: ['da', 'en'],
			locations: [{
				locationId: 'tarm',
				opened: '20180723',
				closed: null,
				rooms: 1
			}]
		}),
	]
});


export {venueData};