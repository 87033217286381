const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'escape-skagen',
	name: 'Escape Skagen',
	website: 'https://escapeskagen.dk',
	locations: [{
		id: 'skagen',
		cityId: 'skagen',
		opened: '20190717',
		closed: null,
		address: 'Trindelvej 19, 9990 Skagen',
		coordinates: {lng: '10.576977', lat: '57.724693'},
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'command-bunker',
			name: {da: 'Kommandobunker', en: 'Command Bunker'},
			languages: ['da', 'en'],
			teamSizeMin: 3,
			minimumAge: 12,
			minimumAgeNoAdult: 15,
			locations: [{
				locationId: 'skagen',
				opened: '20190717',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'harrys-hideout',
			name: {da: 'Harrys Gemmested', en: 'Harry\'s Hideout'},
			languages: ['da', 'en'],
			teamSizeMin: 3,
			minimumAge: 12,
			minimumAgeNoAdult: 15,
			locations: [{
				locationId: 'skagen',
				opened: '20191028',
				closed: null,
				rooms: 1
			}]
		})
	]
});


export {venueData};