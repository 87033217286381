/* eslint-disable camelcase */
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';

/* Log environment */
let env = process.env.NODE_ENV;
console.log('ENVIRONMENT: ', env);

/* Connect to firebase */
let firebaseConfig = {
	apiKey: 'AIzaSyB5QHpACwmhQPMLltFEONp0WbG_J1MgWuI',
	authDomain: 'escapades.firebaseapp.com',
	databaseURL: 'https://escapades.firebaseio.com',
	projectId: 'escapades',
	storageBucket: 'escapades.appspot.com',
	messagingSenderId: '302030931350',
	appId: '1:302030931350:web:102c6ed3bd4f05c5fd89b7',
	measurementId: 'G-MCJYJXQNQ8'
};

/* Initialize firebase with alanalytics */
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase.firestore;