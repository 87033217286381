const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'holbaek-museum',
	name: 'Holbæk Museum',
	website: 'https://vestmuseum.dk/escape-room-paa-holbaek-museum/',
	locations: [{
		id: 'holbaek',
		cityId: 'holbaek',
		opened: '19111101',
		closed: null,
		address: 'Klosterstræde 18, 4300 Holbæk',
		coordinates: {lat: '55.716374', lng: '11.712589'},
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'mutiny-on-the-good-intention',
			name: {da: 'Mytteriet på skibet Den Gode Hensigt', en: 'Mutiny on the ship "The Good Intention"'},			
			minutes: 30,
			teamSizeMin: 1,
			teamSizeMax: 5,
			minimumAge: 11,
			minimumAgeNoAdult: 14,
			locations: [{
				locationId: 'holbaek',
				opened: '20200701',
				closed: null,
				rooms: 1
			}]
		}),
	]
});


export {venueData};