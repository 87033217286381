/**
 * Sort array of objects by object property
 * @param {array} array 
 * @param {string} propertyName 
 * @param {string} order 
 */
/* Sort array of objects by specific object property */
function sortArrayByProperty(array, propertyName, order = 'ASC',
	secondPropertyName = null, secondOrder = 'ASC',
	thirdPropertyName = null, thirdOrder = 'ASC',
	languageId
) {
	let sortedArray = array.sort(function(a, b) {
		/* Sort by third property */
		if (
			a[propertyName] === b[propertyName] && secondPropertyName &&
			a.hasOwnProperty(secondPropertyName) && b.hasOwnProperty(secondPropertyName) &&
			a[secondPropertyName] === b[secondPropertyName] && thirdPropertyName
		) {
			if (
				a.hasOwnProperty(thirdPropertyName) && a[thirdPropertyName] &&
				b.hasOwnProperty(thirdPropertyName) && b[thirdPropertyName] 
			) {
				const valueA = (a[thirdPropertyName].hasOwnProperty(languageId) ? a[thirdPropertyName][languageId] : a[thirdPropertyName]);
				const valueB = (b[thirdPropertyName].hasOwnProperty(languageId) ? b[thirdPropertyName][languageId] : b[thirdPropertyName]);
				if (typeof valueA === 'number' && typeof valueB === 'number') {
					if (thirdOrder === 'ASC') return valueA - valueB;
					else if (thirdOrder === 'DESC') return valueB - valueA;
				}
				if (typeof valueA === 'string' && typeof valueB === 'string') {
					let stringA = valueA.toLowerCase();
					let stringB = valueB.toLowerCase();
					if (stringA < stringB) return (thirdOrder === 'ASC' ? -1 : 1);
					if (stringA > stringB) return (thirdOrder === 'ASC' ? 1 : -1);
					return 0;
				}
				console.error('C: Type must be number or string: ', typeof a[propertyName], typeof b[propertyName]);
				return 0;
			}
			return 0;
		}

		/* Sort by secondary property */
		
		if (a[propertyName] === b[propertyName] && secondPropertyName) {
			if (
				a.hasOwnProperty(secondPropertyName) && a[secondPropertyName] &&
				b.hasOwnProperty(secondPropertyName) && b[secondPropertyName] 
			) {
				const valueA = (a[secondPropertyName].hasOwnProperty(languageId) ? a[secondPropertyName][languageId] : a[secondPropertyName]);
				const valueB = (b[secondPropertyName].hasOwnProperty(languageId) ? b[secondPropertyName][languageId] : b[secondPropertyName]);
			
				if (typeof valueA === 'number' && typeof valueB) {
					if (secondOrder === 'ASC') return valueA - valueB;
					else if (secondOrder === 'DESC') return valueB - valueA;
				}
				if (typeof valueA === 'string' && typeof valueB === 'string') {
					let stringA = valueA.toLowerCase();
					let stringB = valueB.toLowerCase();
					if (stringA < stringB) return (secondOrder === 'ASC' ? -1 : 1);
					if (stringA > stringB) return (secondOrder === 'ASC' ? 1 : -1);
					return 0;
				}
				console.error('B: Type must be number or string: ', typeof valueA, typeof valueB);
				return 0;
			} 
			return 0;
		} 

		/* Sort by primary property */
		const valueA = (a[propertyName].hasOwnProperty(languageId) ? a[propertyName][languageId] : a[propertyName]);
		const valueB = (b[propertyName].hasOwnProperty(languageId) ? b[propertyName][languageId] : b[propertyName]);
		if (typeof valueA === 'number' && typeof valueB === 'number') {
			if (order === 'ASC') return valueA - valueB;
			else if (order === 'DESC') return valueB - valueA;
		}
		if (typeof valueA === 'string' && typeof valueB === 'string') {
			let stringA = valueA.toLowerCase();
			let stringB = valueB.toLowerCase();
			if (stringA < stringB) return (order === 'ASC' ? -1 : 1);
			if (stringA > stringB) return (order === 'ASC' ? 1 : -1);
			return 0;
		}
		console.error('A: Type must be number or string: ', typeof valueA, typeof valueB);
		return 0;
	});
	return sortedArray;
};




export {sortArrayByProperty};