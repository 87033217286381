const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'escape-denmark',
	name: 'Escape Denmark',
	website: 'http://www.escapedenmark.dk',
	locations: [{
		id: 'frederikshavn',
		cityId: 'frederikshavn',
		opened: '20171005',
		closed: '20201216',
		address: 'Kirkegade 2 st., 9900 Frederikshavn',
		coordinates: {lat: '57.440256', lng: '10.53704'},
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'the-puppeteers-workshop',
			name: {da: 'Dukkeførerens Værksted', en: 'The Puppeteer\'s Workshop'},
			languages: ['en'],
			teamSizeMin: 3,
			difficulty: 66.67,
			locations: [{
				locationId: 'frederikshavn',
				opened: '20171005',
				closed: '20201216',
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'trapped-in-amazonas',
			name: {da: 'Fanget i Amazonas', en: 'Trapped in Amazonas'},
			languages: ['en'],
			teamSizeMin: 3,
			minimumAge: 8,
			minimumAgeNoAdult: 13,
			difficulty: 50,
			tagIds: ['kids', 'beginners'],
			locations: [{
				locationId: 'frederikshavn',
				opened: '20171005',
				closed: '20201216',
				rooms: 1
			}]
		}),
	]
});


export {venueData};