const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'clockedin',
	name: 'ClockedIn',
	website: 'http://www.clockedin.dk',
	locations: [{
		id: 'copenhagen',
		cityId: 'copenhagen',
		opened: '20150101',
		closed: null,
		address: 'Brolæggerstræde 6, 1211 København',
		coordinates: {lat: '55.677831', lng: '12.574943'},
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'the-cocktail-party-of-vlad-d',
			name: {da: 'Vlad D. Hansons Cocktail Party', en: 'The Cocktail Party of Vlad D.'},
			languages: ['da', 'en'],
			teamSizeMin: 3,
			teamSizeMax: 5,
			minimumAge: 10,
			minimumAgeNoAdult: 16,
			difficulty: 90,
			locations: [{
				locationId: 'copenhagen',
				opened: '20161201',
				closed: '20241102',
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-murder-mystery-at-the-office',
			name: {da: 'Mordgåden på Detektivkontoret', en: 'The Murder Mystery at the Office'},
			languages: ['da', 'en'],
			teamSizeMax: 5,
			minimumAge: 10,
			minimumAgeNoAdult: 16,
			difficulty: 50,
			tagIds: ['beginners'],
			locations: [{
				locationId: 'copenhagen',
				opened: '20150101',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-mystery-of-the-secret-warehouse',
			name: {da: 'Mysteriet i De Hemmelige Lagerrum', en: 'The Mystery of the Secret Warehouse'},
			languages: ['da', 'en'],
			teamSizeMax: 5,
			minimumAge: 10,
			minimumAgeNoAdult: 16,
			difficulty: 75,
			locations: [{
				locationId: 'copenhagen',
				opened: '20150101',
				closed: null,
				rooms: 1
			}]
		}),
	]
});

export {venueData};