/**
 * Get current date
 * @returns {string}
 */
const getCurrentDate = () => {
	const dateObj = new Date();
	const dateStr = 
		dateObj.getFullYear() + 
		(dateObj.getMonth() + 1 < 10 ? '0' : '') + (dateObj.getMonth() + 1) +
		(dateObj.getDate() < 10 ? '0' : '') + dateObj.getDate();
	return dateStr;
};

const getFormattedDate = (languageId, dateStr) => {
	const monthsDa = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'];
	const monthsEn = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

	let formattedDate = dateStr;
	if (languageId === 'da') {
		formattedDate	= 
			parseInt(dateStr.toString().substring(6)) + '. ' + 
			monthsDa[parseInt(dateStr.toString().substring(4, 6)) - 1] + ', ' + 
			dateStr.toString().substring(0, 4);
	} else {
		formattedDate = 
			monthsEn[parseInt(dateStr.toString().substring(4, 6)) - 1] + ' ' + 
			parseInt(dateStr.toString().substring(6)) + ', ' +
			dateStr.toString().substring(0, 4);
	}

	return formattedDate;
};

export {getCurrentDate, getFormattedDate};