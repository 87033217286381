import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {checkIfVenueIsClosed} from 'helpers/venues-helper';
import {checkIfRoomIsOpened, checkIfRoomIsClosed} from 'helpers/rooms-helper';
import {uiTexts} from 'data/ui-texts';
import './venues.scss';

const Venues = ({showClosed, languageId, deviceInfo, venues}) => {
	return (
		<div className={'Venues ' + deviceInfo.orientation}>

			{/* Loop over venues */}			
			{venues.map((venue) => {
				/* Get venue image */
				let bgImg = require('../../assets/images/default.jpg');
				try {
					bgImg = require('../../assets/images/venues/' + venue.id + '.jpg');
				} catch (e) {
					console.error('Could not find image: ' + venue.id + '.jpg');
				}
				
				/* Get link to venue */
				let linkTo = '/' + venue.id;

				/* Check if venue is closed */
				const venueIsClosed = checkIfVenueIsClosed(venue);

				/* Get number of rooms */
				let numberOfRooms = 0;
				if (venue.rooms) {
					if (showClosed) {
						numberOfRooms = venue.rooms.length;
					} else {
						numberOfRooms = venue.rooms.filter((r) => {
							return (checkIfRoomIsOpened(r) === true && checkIfRoomIsClosed(r) === false);
						}).length;
					}
				}

				return (
					<Link 
						key={venue.id} 
						style={{backgroundImage: 'url(' + bgImg + ')'}}
						className="Venues-venue" 
						to={linkTo}
					>
						{venueIsClosed && <div className="Venues-venueStatus">
							<span>{uiTexts.closed[languageId]}</span>
						</div>}
						<div className="Venues-venueInfo">
							<div className="Venues-venueName">{venue.name}</div>
							<div className="Venues-numberOfRooms">
								{numberOfRooms} {(numberOfRooms > 1 
									? uiTexts.rooms[languageId] : uiTexts.room[languageId])}
							</div>
						</div>
					</Link>
				);
			})}
		</div>
	);
};


Venues.propTypes = {
	showClosed: PropTypes.bool.isRequired,
	languageId: PropTypes.string.isRequired,
	deviceInfo: PropTypes.object.isRequired,
	venues: PropTypes.array.isRequired
};

export default Venues;