const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'midgaard-event',
	name: 'Midgaard Event',	
	website: 'http://www.escaperoom.dk',
	locations: [{
		id: 'copenhagen',
		cityId: 'copenhagen',
		opened: '20160601',
		address: 'Aldersrogade 6A, 2100 København',
		coordinates: {lng: '12.561498', lat: '55.706739'},
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'da-vinci',
			name: {da: 'Da Vinci', en: 'Da Vinci'},
			languages: ['da', 'en'],
			teamSizeMin: 3,
			minimumAge: 6,
			minimumAgeNoAdult: 12,
			difficulty: 50,
			tagIds: ['kids', 'beginners'],
			locations: [{
				locationId: 'copenhagen',
				opened: '20160601',
				closed: null,
				rooms: 3
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'downfall',
			name: {da: 'Downfall', en: 'Downfall'},
			languages: ['da', 'en'],
			minutes: 90,
			teamSizeMin: 3,
			minimumAge: 6,
			minimumAgeNoAdult: 12,
			tagIds: ['live-actors'],
			locations: [{
				locationId: 'copenhagen',
				opened: '20210816',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'outbreak',
			name: {da: 'Outbreak', en: 'Outbreak'},
			languages: ['da', 'en'],
			minutes: 75,
			teamSizeMin: 3,
			minimumAge: 6,
			minimumAgeNoAdult: 12,
			difficulty: 50,
			tagIds: ['kids', 'beginners'],
			locations: [{
				locationId: 'copenhagen',
				opened: '20160601',
				closed: null,
				rooms: 10
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'sherlock',
			name: {da: 'Sherlock', en: 'Sherlock'},
			languages: ['da', 'en'],
			minutes: 75,
			teamSizeMin: 6,
			teamSizeMax: 12,
			minimumAge: 6,
			minimumAgeNoAdult: 12,
			difficulty: 70,
			locations: [{
				locationId: 'copenhagen',
				opened: '20170819',
				closed: null,
				rooms: 1
			}]
		}),
	]
});


export {venueData};