const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'mystery-makers',
	name: 'Mystery Makers',
	website: 'http://www.mysterymakers.dk',
	locations: [{
		id: 'copenhagen',
		cityId: 'copenhagen',
		opened: '20140101',
		closed: null,
		address: 'Grønningen 15, 1270 København',
		coordinates: {lat: '55.689264', lng: '12.590451'},
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'the-killing-room',
			name: {da: 'The Killing Room', en: 'The Killing Room'},
			languages: ['da', 'en'],
			teamSizeMin: 6,
			teamSizeMax: 15,
			minimumAge: 12,
			minimumAgeNoAdult: 15,
			locations: [{
				locationId: 'copenhagen',
				opened: '20170424',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-lab',
			name: {da: 'The Lab', en: 'The Lab'},
			languages: ['da', 'en'],
			teamSizeMin: 4,
			teamSizeMax: 9,
			minimumAge: 7,
			minimumAgeNoAdult: 13,
			tagIds: ['kids', 'beginners'],
			locations: [{
				locationId: 'copenhagen',
				opened: '20160201',
				closed: null,
				rooms: 4
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-ritual',
			name: {da: 'The Ritual', en: 'The Ritual'},
			languages: ['da', 'en'],
			teamSizeMin: 4,
			teamSizeMax: 9,
			minimumAge: 12,
			minimumAgeNoAdult: 15,
			locations: [{
				locationId: 'copenhagen',
				opened: '20140000',
				closed: null,
				rooms: 2
			}]
		}),
	]
});


export {venueData};