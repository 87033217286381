const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'escape-room-roskilde',
	name: 'Escape Room Roskilde',
	website: 'http://escaperoomroskilde.dk',
	locations: [{
		id: 'roskilde',
		cityId: 'roskilde',
		opened: '2020601',
		address: 'Margrethehåbsvej 114, 4000 Roskilde',
		coordinates: {lat: '55.644878', lng: '12.035398'},
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'alcatraz-2.0',
			name: {da: 'Alcatraz 2.0', en: 'Alcatraz 2.0'},
			languages: ['da', 'en'],
			minimumAge: 10,
			difficulty: 70,
			locations: [{
				locationId: 'roskilde',
				opened: '20220529',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-christmas-presents',
			name: {da: 'Julegaverne', en: 'The Christmas presents'},
			languages: ['da', 'en'],
			teamSizeMin: 1,
			teamSizeMax: 5,
			difficulty: 50,
			tagIds: ['family'],
			locations: [{
				locationId: 'roskilde',
				opened: '20201031',
				closed: '20201221',
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-criminals-workshop',
			name: {da: 'Forbrydernes Værksted', en: 'The Criminals\' Workshop'},
			languages: ['da', 'en'],
			teamSizeMin: 1,
			teamSizeMax: 8,
			minimumAge: 10,
			locations: [{
				locationId: 'roskilde',
				opened: '20210522',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-secret-vaccine',
			name: {da: 'Mission Nervegas', en: 'Mission Nerve Gas'},
			languages: ['da', 'en'],
			minutes: 70,
			minimumAge: 10,
			difficulty: 50,
			locations: [{
				locationId: 'roskilde',
				opened: '20201114',
				closed: '2024-10-21',
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-stolen-mona-lisa',
			name: {da: 'Det Stjålne Monsa Lisa', en: 'The Stolen Mona Lisa'}, 
			languages: ['da', 'en'],
			minimumAge: 10,
			difficulty: 60,
			locations: [{
				locationId: 'roskilde',
				opened: '20200601',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'afterski-bar',
			name: {da: 'Afterski Bar', en: 'Afterski Bar'}, 
			languages: ['da', 'en'],
			minimumAge: 10,
			minutes: 70,
			locations: [{
				locationId: 'roskilde',
				opened: '20240901',
				closed: null,
				rooms: 1
			}]
		}),
	]
});

export {venueData};