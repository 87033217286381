const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'hotel-trapped',
	name: 'Hotel Trapped',	
	website: 'https://www.hoteltrapped.dk',
	locations: [{
		id: 'kolding',
		cityId: 'kolding',
		opened: '20190601',
		closed: null,
		address: 'Niels Bohrs Vej 3D, 6000 Kolding',
		coordinates: {lng: '9.46622', lat: '55.504055'}
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'room-7',
			name: {da: 'Værelse 7', en: 'Room 7'},
			minimumAge: 13,
			teamSizeMax: 7,
			difficulty: 50,
			locations: [{
				locationId: 'kolding',
				opened: '20190601',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'room-13',
			name: {da: 'Værelse 13', en: 'Room 13'},
			minimumAge: 15,
			teamSizeMax: 7,
			locations: [{
				locationId: 'kolding',
				opened: '20200911',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-murder-of-elinor',
			name: {da: 'Mordet på Elinor', en: 'The Murder of Elinor'},
			teamSizeMax: 12,
			minimumAge: 8,
			locations: [{
				locationId: 'kolding',
				opened: '20220815',
				closed: null,
				rooms: 2
			}]
		})
	]
});


export {venueData};