const uiTexts = {
	/* Title & subtitle */
	title: 'Escapades',
	subtitle: {
		en: 'a list of escape rooms in',
		da: 'en oversigt over escape rooms i'
	},
	denmark: {
		en: 'Denmark',
		da: 'Danmark'
	},
	dk: {
		en: 'DK',
		da: 'DK'
	},

	/* About */
	popupAbout: {
		title: {
			da: 'Om escapades.dk',
			en: 'About escapades.dk'
		},
		text: {
			da: `Escapades.dk er en oversigt over alle Danmarks escape rooms. Her kan du nemt finde et escape room, der passer til lige netop dig og din gruppe, når lysten til at undslippe melder sig. 
				<br /><br />Siden vedligeholdes af Diana og Rasmus, et par nørdede escape room-entusiaster, som spiller under holdnavnet "Octopi".
				<br /><br />Mangler vi et rum? Så skriv til os på <a href="mailto:escapadesdk@gmail.com">escapadesdk@gmail.com</a>.`,
			en: `Escapades.dk provides an overview of all the escape rooms in Denmark. Here you can easily find an escape room that matches the needs of you and your group when the urge to escape presents itself.
				<br /><br />The site is maintained by Diana and Rasmus, a couple of nerdy escape room enthusiasts who plays under the team name "Octopi". 
				<br /><br />Are we missing a room? Write to us at <a href="mailto:escapadesdk@gmail.com">escapadesdk@gmail.com</a>.`
		},
		fbText: {
			da: `Du kan også følge os på <a class="facebook" target="_blank" rel="noopener" href="https://www.facebook.com/escapadesDK">Facebook</a> for at læse nyheder og andet aktuel information om danske escape rooms.`
		},
		linksText: {

		}
	},

	/* Filters / sorting / restuls */
	regions: {
		en: 'Regions',
		da: 'Områder'
	},
	allCities: {
		en: 'All cities',
		da: 'Alle byer'
	},
	includeComing: {
		en: 'Include coming',
		da: 'Inkludér kommende'
	},
	includeClosed: {
		en: 'Include closed',
		da: 'Inkludér lukkede'
	},	
	sortBy: {
		en: 'Sort rooms by',
		da: 'Sorter rum efter'
	},
	resultEscapeRooms: {
		en: 'Escape rooms',
		da: 'Escape rooms'
	},
	resultVenues: {
		en: 'Venues',
		da: 'Firmaer'
	},

	/* Map */
	viewOnMap: {
		en: 'View map',
		da: 'Se kort'
	},

	/* Room / venue info */
	opened: {
		en: 'Opened',
		da: 'Åbnede'
	},
	opens: {
		en: 'Opens',
		da: 'Åbner'
	},
	closed: {
		en: 'Closed',
		da: 'Lukket'
	},
	closed2: {
		en: 'Closed',
		da: 'Lukkede'
	},
	website: {
		en: 'Website',
		da: 'Hjemmeside'
	},
	room: {
		en: 'room',
		da: 'rum'
	},
	rooms: {
		en: 'rooms',
		da: 'rum'
	},
	multipleCities: {
		en: 'multiple cities',
		da: 'flere byer'
	},
	roomOnTheWay: {
		en: 'Room on the way',
		da: 'Rum på vej'
	},
	players: {
		en: 'players',
		da: 'spillere'
	},
	timeLimit: {
		en: 'Time limit',
		da: 'Varighed'
	},
	minutes: {
		en: 'minutes',
		da: 'minutter'
	},
	languages: {
		en: 'Languages',
		da: 'Sprog'
	},
	age: {
		en: 'Age',
		da: 'Alder'
	},
	noDescription: {
		en: 'No description yet',
		da: 'Ingen beskrivelse endnu.'
	},
	teamSize: {
		en: 'Team size',
		da: 'Holdstørrelse'
	},

	/* Auth */
	login: {
		en: 'Login',
		da: 'Login'
	},
	email: {
		en: 'e-mail',
		da: 'e-mail'
	},
	password: {
		en: 'password',
		da: 'password'
	},
	hideCompleted: {
		en: 'Hide completed',
		da: 'Skjul gennemførte'
	}
};

export {uiTexts};

