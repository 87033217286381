const venueSortablesData = [
	{
		id: 'sortableOpened',
		title: {
			en: 'Date opened',
			da: 'Åbningsdato'
		},
		sortDirection: 'DESC'
	},	
	{
		id: 'sortableVenueName',
		title: {
			en: 'Venue name',
			da: 'Firmanavn'
		},
		sortDirection: 'ASC'
	},
];

export {venueSortablesData};