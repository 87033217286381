const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'breakout-room',
	name: 'Breakout Room',
	website: 'http://www.breakoutroom.dk',
	locations: [
		{
			id: 'copenhagen',
			cityId: 'copenhagen',
			opened: '20160101',
			closed: null,
			address: 'Amaliegade 32, 1256 København',
			coordinates: {lat: "55.685539", lng: "12.594736"}
		},
		{
			id: 'roedovre',
			cityId: 'roedovre',
			opened: '20160101',
			closed: null,
			address: 'Tæbyvej 3, 2610 Rødovre',
			coordinates: {lat: "55.685270", lng: "12.462986"}
		},
	],	
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'basement-13',
			name: {da: 'Basement 13', en: 'Basement 13'},
			languages: ['en'],
			teamSizeMin: 3,
			minimumAge: 12,
			minimumAgeNoAdult: 18,
			difficulty: 70,
			locations: [
				{
					locationId: 'copenhagen',
					opened: '20160101',
					closed: null,
					rooms: 1
				},
				{
					locationId: 'roedovre',
					opened: '20160101',
					closed: null,
					rooms: 1
				}
			]
		}),
		Object.assign({}, roomTemplate, {
			id: 'black-magic',
			name: {da: 'Black Magic', en: 'Black Magic'},
			languages: ['en'],
			teamSizeMin: 5,
			teamSizeMax: 9,
			minimumAge: 12,
			minimumAgeNoAdult: 18,
			difficulty: 80,
			locations: [
				{
					locationId: 'copenhagen',
					opened: '20160101',
					closed: null,
					rooms: 2
				},
				{
					locationId: 'roedovre',
					opened: '20160101',
					closed: null,
					rooms: 2
				}
			]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-jungle-mystery',
			name: {da: 'Junglemysteriet', en: 'The Jungle Mystery'},
			languages: ['da'],
			teamSizeMin: 4,
			teamSizeMax: 8,
			difficulty: 50,
			tagIds: ['battle-room', 'kids'],
			locations: [{
				locationId: 'roedovre',
				opened: '20200320',
				closed: null,
				rooms: 2
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-time-machine',
			name: {da: 'Tidsmaskinen', en: 'The Time Machine'},
			languages: ['en'],
			teamSizeMin: 3,
			minimumAge: 12,
			minimumAgeNoAdult: 18,
			difficulty: 60,
			tagIds: ['beginners'],
			locations: [{
				locationId: 'copenhagen',
				opened: '20161020',
				closed: null,
				rooms: 2
			},]
		}),
	]
});


export {venueData};