const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'escape-viborg',
	name: 'Escape Viborg',	
	website: 'http://www.escapeviborg.dk',
	locations: [{
		id: 'viborg',
		cityId: 'viborg',
		opened: '20160201',
		closed: null,
		address: 'Venøvej 1, 8800 Viborg',
		coordinates: {lat: '56.450981', lng: '9.362663'},
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'the-jewel-heist',
			name: {da: 'Juvelkuppet', en: 'The Jewel Heist'},
			languages: ['da', 'en'],
			teamSizeMax: 12,
			minimumAge: 15,
			difficulty: 70,
			locations: [{
				locationId: 'viborg',
				opened: '20160201',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'your-worst-nightmare',
			name: {da: 'Dit Værste Mareridt', en: 'Your Worst Nightmare'},
			languages: ['da', 'en'],
			teamSizeMin: 4,
			minimumAge: 16,
			difficulty: 90,
			locations: [{
				locationId: 'viborg',
				opened: '20220219',
				closed: null,
				rooms: 1
			}]
		})
	]
});


export {venueData};