const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'funcenter',
	name: 'FunCenter',
	website: 'http://www.funcenter.dk',
	locations: [{
		id: 'aalborg',
		cityId: 'aalborg',
		opened: '20181201',
		address: 'Boeck Hansens vej 14, 9000 Aalborg',
		coordinates: {lng: 9.93439, lat: 57.03427},
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'the-box',
			name: {da: 'Boksen', en: 'The Box'},			
			languages: ['da', 'en'],
			teamSizeMax: 5,
			difficulty: 110,
			locations: [{
				locationId: 'aalborg',
				opened: '20220101',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-bunker',
			name: {da: 'Bunkeren', en: 'The Bunker'},
			languages: ['da', 'en'],
			teamSizeMax: 5,
			difficulty: 100,
			locations: [{
				locationId: 'aalborg',
				opened: '20191201',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-jungle',
			name: {da: 'Junglen', en: 'The Jungle'},
			languages: ['da', 'en'],
			teamSizeMax: 5,
			difficulty: 100,
			locations: [{
				locationId: 'aalborg',
				opened: '20220101',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-killer',
			name: {da: 'Morderen', en: 'The Killer'},
			languages: ['da', 'en'],
			teamSizeMax: 5,
			locations: [{
				locationId: 'aalborg',
				opened: '20181201',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-prison',
			name: {da: 'Fængslet', en: 'The Prison'},
			languages: ['da', 'en'],
			teamSizeMax: 5,
			locations: [{
				locationId: 'aalborg',
				opened: '20181201',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-submarine',
			name: {da: 'Ubåden', en: 'The Submarine'},
			languages: ['da', 'en'],
			teamSizeMax: 5,
			difficulty: 83.33,
			locations: [{
				locationId: 'aalborg',
				opened: '20210301',
				closed: null,
				rooms: 1
			}]
		}),
	]
});


export {venueData};