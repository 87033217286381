const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'great-escape',
	name: 'Great Escape',	
	website: 'https://great-escape.dk',
	locations: [{
		id: 'esbjerg',
		cityId: 'esbjerg',
		opened: '20190824',
		closed: true,
		address: 'Søhalevej 5, 6715 Esbjerg',
		coordinates: {lat: '55.53895', lng: '8.579998'},
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'the-cannibal',
			name: {da: 'Kannibalen', en: 'The Cannibal'},			
			teamSizeMax: 5,
			difficulty: 65,
			locations: [{
				locationId: 'esbjerg',
				opened: '20190824',
				closed: '20231130', // TODO: not sure, around this time
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-room',
			name: {da: 'The Room', en: 'The Room'},
			locations: [{
				locationId: 'esbjerg',
				opened: '20211001',
				closed: '20231130', // TODO: not sure, around this time
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-wizard',
			name: {da: 'Troldmanden', en: 'The Wizard'},
			teamSizeMax: 5,
			difficulty: 90,
			locations: [{
				locationId: 'esbjerg',
				opened: '20190824',
				closed: '20231130', // TODO: not sure, around this time
				rooms: 1
			}]
		})
	]
});


export {venueData};