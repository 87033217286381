const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'escape-game-odense',
	name: 'Escape Game Odense',
	website: 'https://www.escapegameodense.dk',
	locations: [{
		id: 'odense',
		cityId: 'odense',
		opened: '20160501',
		closed: '20240413',
		address: 'Gråbrødre Plads 4, 4. tv, 5000 Odense',
		coordinates: {lng: '10.386143', lat: 'lat: 55.396386'},
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'crime-scene',
			name: {da: 'Crime Scene', en: 'Crime Scene'},
			languages: ['da', 'en'],
			minimumAge: 12,
			minimumAgeNoAdult: 16,
			locations: [{
				locationId: 'odense',
				opened: '20221001',
				closed: '20240413',
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-spy-room',
			name: {da: 'Spionrummet', en: 'The Spy Room'},
			languages: ['da', 'en'],
			minimumAge: 12,
			minimumAgeNoAdult: 18,
			tagIds: ['kids'],
			locations: [{
				locationId: 'odense',
				opened: '20180301',
				closed: '20220801',
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-virus-room',
			name: {da: 'Virusrummet', en: 'The Virus Room'},
			languages: ['da', 'en'],
			teamSizeMax: 4,
			minimumAge: 12,
			minimumAgeNoAdult: 16,
			tagIds: ['kids'],
			locations: [{
				locationId: 'odense',
				opened: '20190501',
				closed: '20240413',
				rooms: 1
			}]
		}),
	]
});


export {venueData};