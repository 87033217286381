const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'garderhojfortet',
	name: 'Garderhøjfortet',	
	website: 'https://matematiskescaperoom.dk',
	locations: [{
		id: 'copenhagen',
		cityId: 'copenhagen',
		opened: '20130927',
		closed: null,
		address: 'Garderhøjfort 4, 2820 Gentofte',
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'flood',
			name: {da: 'Oversvømmelsesrummet', en: 'The Flooding Room'},			
			languages: ['da'],
			teamSizeMax: 6,
			teamSizeMin: 2,
			minutes: 45,
			locations: [{
				locationId: 'copenhagen',
				opened: '20210705',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'canon',
			name: {da: 'Kanonrummet', en: 'The Canon Room'},			
			languages: ['da'],
			teamSizeMax: 6,
			teamSizeMin: 2,
			minutes: 45,
			locations: [{
				locationId: 'copenhagen',
				opened: '20210705',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'strategy',
			name: {da: 'Strategirummet', en: 'The Strategy Room'},			
			languages: ['da'],
			teamSizeMax: 6,
			teamSizeMin: 2,
			minutes: 45,
			locations: [{
				locationId: 'copenhagen',
				opened: '20210705',
				closed: null,
				rooms: 1
			}]
		}),
	]
});


export {venueData};