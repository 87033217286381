const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'middelaldercentret',
	name: 'Middelaldercentret',
	website: 'http://www.middelaldercentret.dk/p/escape-room/',
	locations: [{
		id: 'nykoebing-falster',
		cityId: 'nykoebing-falster',
		opened: '20190701',
		closed: null,
		address: 'Ved Hamborgskoven 2, 4800 Nykøbing Falster',
		coordinates: {lng: '11.841098', lat: '54.775015'},
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'the-lab',
			name: {da: 'Laboratorium', en: 'Laboratory'},
			teamSizeMax: 6,
			difficulty: 30,
			locations: [{
				locationId: 'nykoebing-falster',
				opened: '20190701',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-secret-of-the-library',
			name: {da: 'Bibliotek', en: 'Library'},
			teamSizeMax: 6,
			difficulty: 60,
			locations: [{
				locationId: 'nykoebing-falster',
				opened: '20190701',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'observatory',
			name: {da: 'Obervatorium', en: 'Observatory'},
			teamSizeMax: 6,
			difficulty: 60,
			locations: [{
				locationId: 'nykoebing-falster',
				opened: '20201201',
				closed: null,
				rooms: 1
			}]
		}),
	]
});


export {venueData};