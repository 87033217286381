const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'bowl-n-fun',
	name: 'Bowl\'n\'Fun',
	website: 'https://bowlnfun.dk',
	locations: [
		{
			id: 'aalborg',
			cityId: 'aalborg',
			opened: '20200124',
			address: 'Fiskene 10, 9200 Aalborg',
			coordinates: {lng: '9.867172', lat: '56.998486'}
		},
		{
			id: 'haderslev',
			cityId: 'haderslev',
			opened: '20170211',
			address: 'Vinkelvej 19, 6100 Haderslev',
			coordinates: {lng: "9.463611", lat: "55.510655"},
		},
		{
			id: 'kolding',
			cityId: 'kolding',
			opened: '20170211',
			address: 'Skovvangen 35 6000 Kolding',
			coordinates: {lng: "9.463611", lat: "55.510655"},
		},
		{
			id: 'naestved',
			cityId: 'naestved',
			opened: '20171117',
			address: 'Erantisvej 38 4700 Næstved',
			coordinates: {lat: "55.236561", lng: "11.777403"},
		},
		{
			id: 'odense',
			cityId: 'odense',
			opened: '20171007',
			address: 'Grønløkken 3, 5000 Odense',
			coordinates: {lat: "55.396546", lng: "10.364425"},
		},
		{
			id: 'skive',
			cityId: 'skive',
			opened: '20170211',
			address: 'Sdr. Boulevard 13, 7800 Skive',
			coordinates: {lat: "56.545650", lng: "9.030929"},
		},
		{
			id: 'soenderborg',
			cityId: 'soenderborg',
			opened: '20190208',
			address: 'Stenager 17 D, 6400 Sønderborg',
			coordinates: {lng: "9.809310", lat: "54.923512"},
		},
		{
			id: 'svendborg',
			cityId: 'svendborg',
			opened: '20170211',
			address: 'Nyborgvej 4, 5700 Svendborg',
			coordinates: {lng: "10.620885", lat: "55.062587"},
		},
		{
			id: 'vejle',
			cityId: 'vejle',
			opened: '20171005',
			address: 'Enghavevej 9, 7100 Vejle',
			coordinates: {lat: "55.640452", lng: "9.710206"},
		},
		{
			id: 'viborg',
			cityId: 'viborg',
			opened: '20181001',
			address: 'Tingvej 7, 8800 Viborg',
			coordinates: {lat: "56.454981", lng: "9.402547"}
		}
	],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'abandoned-reactor',
			name: {da: 'Abandoned Reactor', en: 'Abandoned Reactor'},
			languages: ['en'],
			teamSizeMin: 4,
			difficulty: 80,
			locations: [{
				locationId: 'aalborg',
				opened: '20200124',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'haunted-mansion',
			name: {da: 'Haunted Mansion', en: 'Haunted Mansion'},
			languages: ['da', 'en'],
			teamSizeMin: 4,
			difficulty: 70,
			locations: [{
				locationId: 'aalborg',
				opened: '20200124',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'hunters-lodge',
			name: {da: 'Hunter\'s Lodge', en: 'Hunter\'s Lodge'},
			languages: ['da', 'en'],
			teamSizeMin: 4,
			difficulty: 80,
			locations: [
				{
					locationId: 'haderslev',
					opened: '20170211',
					closed: null,
					rooms: 1
				},
				{
					locationId: 'kolding',
					opened: '20170211',
					closed: null,
					rooms: 1
				},
				{
					locationId: 'naestved',
					opened: '20171117',
					closed: null,
					rooms: 1
				},
				{
					locationId: 'odense',
					opened: '20171007',
					closed: null,
					rooms: 1
				},
				{
					locationId: 'skive',
					opened: '20170211',
					closed: null,
					rooms: 1
				},
				{
					locationId: 'soenderborg',
					opened: '20190208',
					closed: null,
					rooms: 1
				},
				{
					locationId: 'svendborg',
					opened: '20170211',
					closed: null,
					rooms: 1
				},
				{
					locationId: 'vejle',
					opened: '20171005',
					closed: null,
					rooms: 1
				},
				{
					locationId: 'viborg',
					opened: '20181001',
					closed: null,
					rooms: 1
				}
			],			
		}),
		Object.assign({}, roomTemplate, {
			id: 'jack-the-ripper',			
			name: {da: 'Jack the Ripper', en: 'Jack the Ripper'},
			languages: ['da', 'en'],
			teamSizeMin: 4,
			difficulty: 87,
			locations: [
				{
					locationId: 'odense',
					opened: '20171007',
					closed: null,
					rooms: 1
				},
				{
					locationId: 'soenderborg',
					opened: '20190208',
					closed: null,
					rooms: 1
				},
			]			
		}),
		Object.assign({}, roomTemplate, {
			id: 'lost-city-of-atlantis',
			name: {da: 'Lost City of Atlantis', en: 'Lost City of Atlantis'},
			languages: ['en'],
			teamSizeMin: 4,
			difficulty: 90,
			locations: [{
				locationId: 'aalborg',
				opened: '20200124',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'medieval-castle',
			name: {da: 'Medieval Castle', en: 'Medieval Castle'},
			languages: ['da', 'en'],
			teamSizeMin: 4,
			difficulty: 90,
			locations: [
				{
					locationId: 'vejle',
					opened: '20171005',
					closed: null,
					rooms: 1
				},
				{
					locationId: 'viborg',
					opened: '20181001',
					closed: null,
					rooms: 1
				}
			]
		}),
		Object.assign({}, roomTemplate, {
			id: 'mission-impossible',
			name: {da: 'Mission Impossible', en: 'Mission Impossible'},
			languages: ['da', 'en'],
			teamSizeMin: 4,
			difficulty: 95,
			locations: [
				{
					locationId: 'naestved',
					opened: '20171117',
					closed: null,
					rooms: 1
				},
				{
					locationId: 'odense',
					opened: '20171007',
					closed: null,
					rooms: 1
				},
			]
		}),
		Object.assign({}, roomTemplate, {
			id: 'pirates-queen-annes-revenge',
			name: {da: 'Pirates - Queen Anne\'s Revenge', en: 'Pirates - Queen Anne\'s Revenge'},
			languages: ['en'],
			teamSizeMin: 4,
			difficulty: 90,
			locations: [{
				locationId: 'aalborg',
				opened: '20200124',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'saw',
			name: {da: 'Saw - Escape from the Jigsaw Killer', en: 'Saw - Escape from the Jigsaw Killer'},
			languages: ['da', 'en'],
			teamSizeMin: 4,
			difficulty: 90,
			locations: [
				{
					locationId: 'odense',
					opened: '20171007',
					closed: null,
					rooms: 1
				},
			]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-chocolate-factory',
			name: {da: 'The Chocolate Factory', en: 'The Chocolate Factory'},
			languages: ['da', 'en'],
			teamSizeMin: 4,
			difficulty: 80,
			locations: [{
				locationId: 'kolding',
				opened: '20170211',
				closed: null,
				rooms: 1
			},]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-forbidden-temple-of-montezuma',
			name: {da: 'The Forbidden Temple of Montezuma', en: 'The Forbidden Temple of Montezuma'},
			languages: ['da'],
			teamSizeMin: 4,
			difficulty: 95,
			locations: [
				{
					locationId: 'kolding',
					opened: '20170211',
					closed: null,
					rooms: 1
				},
				{
					locationId: 'naestved',
					opened: '20171117',
					closed: null,
					rooms: 1
				},
			]
		}),
		Object.assign({}, roomTemplate, {
			id: 'tutankhamuns-tomb',
			name: {da: 'Tutankhamon\'s Tomb', en: 'Tutankhamon\'s Tomb'},
			languages: ['da', 'en'],
			teamSizeMin: 4,
			difficulty: 85,
			locations: [
				{
					locationId: 'viborg',
					opened: '20181001',
					closed: null,
					rooms: 1
				}
			]
		}),
		Object.assign({}, roomTemplate, {
			id: 'walking-death',
			name: {da: 'Walking Death', en: 'Walking Death'},
			languages: ['en'],
			teamSizeMin: 4,
			difficulty: 85,
			locations: [{
				locationId: 'aalborg',
				opened: '20200124',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'philosophers-stone',
			name: {da: 'Philosopher\s Stone', en: 'Philosopher\s Stone'},
			languages: ['en'],
			teamSizeMin: 4,
			locations: [{
				locationId: 'soenderborg',
				opened: '20190208', // TODO: not sure, probably opened later
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'nightmare-hotel',
			name: {da: 'Nightmare Hotel', en: 'Nightmare Hotel'},
			languages: ['en'],
			locations: [{
				locationId: 'svendborg',
				opened: '20240825',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'illuminati',
			name: {da: 'Illuminati', en: 'Illuminati'},
			languages: ['en'],
			locations: [{
				locationId: 'svendborg',
				opened: '20240825',
				closed: null,
				rooms: 1
			}]
		})
	]
});


export {venueData};