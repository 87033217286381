const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'countdown',
	name: 'Countdown',
	locations: [{
		id: 'holstebro',
		cityId: 'holstebro',
		opened: '20191003',
		closed: true,
		address: 'Østergade 18, 7500 Holstebro',
		coordinates: {lng: '8.620631', lat: '56.358089'},
	}],
	website: null,
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'archaeological-expedition',
			name: {da: 'Archaeological Expedition', en: 'Archaeological Expedition'},			
			languages: ['da'],
			locations: [{
				locationId: 'holstebro',
				opened: '20191003',
				closed: true,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-jewel-theft',
			name: {da: 'The Jewel Theft', en: 'The Jewel Theft'},
			languages: ['da'],
			locations: [{
				locationId: 'holstebro',
				opened: '20191003',
				closed: true,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-slaughterhouse',
			name: {da: 'The Slaughterhouse', en: 'The Slaughterhouse'},
			languages: ['da'],
			locations: [{
				locationId: 'holstebro',
				opened: '20191003',
				closed: true,
				rooms: 1
			}]
		})
	]
});


export {venueData};