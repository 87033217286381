import React from 'react';
import PropTypes from 'prop-types';
import {uiTexts} from 'data/ui-texts';
import {roomSortablesData} from 'data/room-sortables-data';
import {venueSortablesData} from 'data/venue-sortables-data';
import Select from 'components/ui/select/select';
import './sorting-panel.scss';

const SortingPanel = (props) => {
	let {
		languageId,
		page,
		sortBy,
		sortDirection,
		handleSort,
		handleSortDirection
	} = props;

	let sortOptions = [];
	if (page === 'rooms') sortOptions = roomSortablesData;
	if (page === 'venues') sortOptions = venueSortablesData;

	return (
		<div className="SortingPanel">
			<div className="SortingPanel-title">{uiTexts.sortBy[languageId]}: </div>
			<div className="SortingPanel-container">
				<div 
					className={'SortingPanel-direction ' + sortDirection} 
					onClick={() => {handleSortDirection();}}
				/>
				<div className="SortingPanel-dropdown">
					<Select 
						languageId={languageId}
						options={sortOptions}
						selectedId={sortBy}
						onSelect={handleSort}
					/>
				</div>
			</div>
		</div>
	);
};


SortingPanel.propTypes = {
	languageId: PropTypes.string.isRequired,
	page: PropTypes.string.isRequired,
	sortBy: PropTypes.string.isRequired,
	sortDirection: PropTypes.string.isRequired,
	handleSort: PropTypes.func.isRequired,
	handleSortDirection: PropTypes.func.isRequired,
};

export default SortingPanel;
