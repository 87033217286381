import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import {uiTexts} from 'data/ui-texts';
import './popup-login.scss';

const PopupLogin = ({isLoggedIn, languageId, deviceInfo}) => {

	/* Input e-mail / password */
	const [email, setEmail] = useState(null);
	const [password, setPassword] = useState(null);

	/* Loading status */
	const [isLoading, setIsLoading] = useState(null);

	/* Feedback */
	const [feedback, setFeedback] = useState(null);
	

	/**
	 * Update input field
	 * @param {obj} event 
	 */
	const handleInput = (event) => {
		setFeedback(null);
		if (event.target.name === 'email') {
			setEmail(event.target.value);
		} else if (event.target.name === 'password') {
			setPassword(event.target.value);
		}
	};

	/**
	 * Handle login
	 * @param {obj} event 
	 */
	const handleLogin = (event) => {
		if (event) event.preventDefault();

		if (isLoading) return;

		setIsLoading(true);
		setFeedback(null);

		let feedback = null;
		if (!feedback && (!email || !password)) {
			feedback = 'emptyFields';
		}

		if (!feedback) {
			firebase.auth().signInWithEmailAndPassword(email, password).catch((error) => {
				if (error.code === 'auth/user-not-found') {
					feedback = 'emailNotFound';
				}
				if (error.code === 'auth/wrong-password') {
					feedback = 'invalidEmailPasswordFormat';
				}
				if (error.code === 'auth/invalid-email') {
					feedback = 'invalidEmail';
				}
				if (!feedback) {
					console.error(error);
					feedback = 'unknownError';
				}
				setIsLoading(false);
				setFeedback(feedback);
					
			});
		} else {
			setIsLoading(false);
			setFeedback(feedback);
		}
	};

	/* Component mounted, scroll to top */
	useEffect(() => {window.scrollTo(0, 0);});

	if (isLoggedIn) {
		return null;
	}

	return (
		<div className={'PopupLogin ' + deviceInfo.orientation}>
			<Link className="PopupLogin-closeLayer" to={'/'} />
			<div className="PopupLogin-content">
				<div className="PopupLogin-title">
					<span>{uiTexts.login[languageId]}</span>
				</div>
				<Link className="PopupLogin-closeBtn" to={'/'} />
				<form className="PopupLogin-form" onSubmit={(e) => {handleLogin(e);}}>
					<input
						name="email"
						type="text"
						placeholder={uiTexts.email[languageId]} 
						autoComplete="section-escapadeslogin email"
						value={email ? email : ''} 
						onChange={(event)=>{handleInput(event);}}
					/>
					<input
						name="password"
						type="password"
						placeholder={uiTexts.password[languageId]}
						autoComplete="section-escapadeslogin password"
						value={password ? password : ''} 
						onChange={(event)=>{handleInput(event);}}
					/>
					<div className="PopupLogin-errorMessage">
						<span>{feedback}</span>
					</div> 
					<div 
						className={'PopupLogin-loginBtn' + (isLoading ? ' loading' : '')}
						onClick={() => {handleLogin();}}
					>
						<span>{uiTexts.login[languageId]}</span>
					</div>
				</form>
			</div>
		</div>
	);
};


PopupLogin.propTypes = {
	isLoggedIn: PropTypes.bool.isRequired,
	languageId: PropTypes.string.isRequired,
	deviceInfo: PropTypes.object.isRequired
};


export default PopupLogin;