const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'escape-copenhagen',
	name: 'Escape Copenhagen',
	website: 'http://www.escape-cph.dk',
	locations: [
		{
			id: 'copenhagen-1',
			cityId: 'copenhagen',
			opened: '20150401',
			closed: null,
			address: 'Nørre Farimagsgade 7, 1364 København',
			coordinates: {lat: '55.680689', lng: '12.564061'},
		}, 
		{
			id: 'copenhagen-2',
			cityId: 'copenhagen',
			opened: '20150401',
			closed: null,
			address: 'Nørre Voldgade 18, 1364 København',
			coordinates: {lat: '55.680689', lng: '12.564061'},
		},
		{
			id: 'copenhagen-3',
			cityId: 'copenhagen',
			opened: '20150401',
			closed: null,
			address: 'Tornebuskegade 1, 1364 København',
			coordinates: {lat: '55.680689', lng: '12.564061'},
		}
	],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'dr-evils-plan',
			name: {da: 'Dr. Evil\'s Plan', en: 'Dr. Evil\'s Plan'},
			languages: ['da', 'en'],
			teamSizeMin: 4,
			teamSizeMax: 8,
			minimumAge: 15,
			minimumAgeNoAdult: 18,
			difficulty: 83.33,
			locations: [{
				locationId: 'copenhagen-2',
				opened: '20190601',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'enter-the-mind',
			name: {da: 'Enter the Mind', en: 'Enter the Mind'},
			languages: ['da', 'en'],
			teamSizeMin: 8,
			teamSizeMax: 14,
			minimumAge: 15,
			minimumAgeNoAdult: 18,
			difficulty: 66.67,
			locations: [{
				locationId: 'copenhagen-2',
				opened: '20170509',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-psychotic-janitor',
			name: {da: 'The Psychotic Janitor', en: 'The Psychotic Janitor'},
			languages: ['da', 'en'],
			teamSizeMin: 4,
			teamSizeMax: 8,
			minimumAge: 15,
			minimumAgeNoAdult: 18,
			difficulty: 50,
			locations: [{
				locationId: 'copenhagen-1',
				opened: '20150401',
				closed: null,
				rooms: 2
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-rogue-general',
			name: {da: 'The Rogue General', en: 'The Rogue General'},
			languages: ['da', 'en'],
			teamSizeMin: 4,
			teamSizeMax: 8,
			minimumAge: 15,
			minimumAgeNoAdult: 18,
			difficulty: 83.33,
			locations: [{
				locationId: 'copenhagen-2',
				opened: '20201101',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-solitary-monk',
			name: {da: 'The Solitary Monk', en: 'The Solitary Monk'},
			languages: ['da', 'en'],
			teamSizeMin: 4,
			teamSizeMax: 8,
			minimumAge: 15,
			minimumAgeNoAdult: 18,
			locations: [{
				locationId: 'copenhagen-1',
				opened: '20151201',
				closed: '20201001',
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-terrorist-cell',
			name: {da: 'The Terrorist Cell', en: 'The Terrorist Cell'},
			languages: ['da', 'en'],
			teamSizeMin: 4,
			teamSizeMax: 8,
			minimumAge: 15,
			minimumAgeNoAdult: 18,
			difficulty: 50,
			locations: [{
				locationId: 'copenhagen-1',
				opened: '20150801',
				closed: null,
				rooms: 2
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'vikings',
			name: {da: 'Vikings', en: 'Vikings'},
			languages: ['da', 'en'],
			teamSizeMin: 4,
			teamSizeMax: 8,
			minimumAge: 15,
			minimumAgeNoAdult: 18,
			difficulty: 66.67,
			tagIds: ['battle-room'],
			locations: [{
				locationId: 'copenhagen-2',
				opened: '20170224',
				closed: null,
				rooms: 2
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'hacked',
			name: {da: 'Hacked', en: 'Hacked'},
			languages: ['da', 'en'],
			teamSizeMin: 3,
			teamSizeMax: 6,
			minimumAge: 15,
			minimumAgeNoAdult: 18,
			difficulty: 66.67,
			tagIds: ['battle-room'],
			locations: [{
				locationId: 'copenhagen-3',
				opened: '20230201',
				closed: null,
				rooms: 2
			}]
		}),
	]
});


export {venueData};