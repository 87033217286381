const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'adventure-house',
	name: 'Adventure House',
	website: 'http://www.adventurehouse.dk',
	locations: [{
		id: 'randers',
		cityId: 'randers',
		opened: '20161101',
		closed: '20201123',
		address: 'Messingvej 48 B, 8940 Randers',
		coordinates: {lng: '10.049781', lat: '56.43217'}
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'agent-8900',
			name: {da: 'Agent 8900', en: 'Agent 8900'},
			teamSizeMax: 5,
			minimumAge: 13,
			minimumAgeNoAdult: 18,
			difficulty: 50,
			locations: [{
				locationId: 'randers',
				opened: '20191124',
				closed: '20201123',
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'director-hallandsens-diamond',
			name: {da: 'Direktør Hallandsens Diamant', en: 'Director Hallandsen\'s Diamond'},
			teamSizeMax: 5,
			minimumAge: 13,
			minimumAgeNoAdult: 18,
			difficulty: 50,
			locations: [{
				locationId: 'randers',
				opened: '20170601',
				closed: '20201123',
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-mad-scientist',
			name: {da: 'Den Gale Videnskabsmand', en: 'The Mad Scientist'},
			teamSizeMax: 5,
			minimumAge: 13,
			minimumAgeNoAdult: 18,
			difficulty: 30,
			locations: [{
				locationId: 'randers',
				opened: '20161101',
				closed: '20201123',
				rooms: 1
			}]
		})
	]
});


export {venueData};