const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'escape-room-randers',
	name: 'Escape Room Randers',
	website: 'https://escaperoomranders.dk',
	locations: [{
		id: 'randers',
		cityId: 'randers',
		opened: '20190701',
		address: 'Nordregrave 8, 8900 Randers',
		coordinates: {lat: '56.462148', lng: '10.038718'},
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'mental-hospital',
			name: {da: 'Mental Hospital', en: 'Mental Hospital'},			
			languages: ['da', 'en'],
			minimumAge: 13,
			difficulty: 75,
			locations: [{
				locationId: 'randers',
				opened: '20220601',
				closed: null,
				roomes: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'murder-at-the-grand-hotel',
			name: {da: 'Murder at The Grand Hotel', en: 'Murder at The Grand Hotel'},
			languages: ['da', 'en'],
			teamSizeMax: 7,
			minimumAge: 13,
			difficulty: 75,
			description: {
				da: `Murder at The Grand Hotel har et klassisk 'find morderen'-tema, der foregår først i 20'erne. Rummet er indrettet godt, og især hotellobbyen er smukt lavet og føles meget ægte. Opgaverne er udfordrende og af varierende typer, og man skal være på mærkerne for at finde og løse dem alle. De meget detaljerede og realistiske dekorationer fik os til at overtænke et par af opgaverne, og til sidst i spillet var vi lidt usikre på, om vi faktisk havde identificeret morderen og kunne slutte spillet. Alt i alt synes vi, det er et godt og udfordrende escape room.`,
				en: `Murder at The Grand Hotel is a classic 'solve the murder'-themed escape room set in the early 20s. The interior design is good, especially the hotel lobby has been decorated beautifully and feels very authentic. The puzzles are tricky and diverse and you have to keep your eyes open to find and solve everything. The very detailed and realistic props made us overthink a few puzzles, and we were a bit unsure about when we had enough info to actually name the murderer and end the game. All in all we found it a good and challenging escape room.`
			},
			locations: [{
				locationId: 'randers',
				opened: '20190701',
				closed: null,
				roomes: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'prison-break',
			name: {da: 'Prison Break', en: 'Prison Break'},
			languages: ['da', 'en'],
			teamSizeMin: 3,
			minimumAge: 13,
			difficulty: 81,
			locations: [{
				locationId: 'randers',
				opened: '20210920',
				closed: null,
				roomes: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'submarine',
			name: {da: 'Submarine', en: 'Submarine'},
			languages: ['da', 'en'],
			teamSizeMax: 7,
			minimumAge: 13,
			difficulty: 70,
			description: {
				da: `Submarine er et rigtig godt rum med et anderledes og spændende tema. Rummets indretning og dekorationer er gennemtænkte og veludførte og skaber den helt rigtige atmosfære. Opgaverne er gode og af varierende typer og kompleksiteter. Vores game masters var meget venlige og engagerede og næsten lige så meget oppe at køre over, hvor tæt vi var på at slå rummets bedste tid, som vi var.`,
				en: `Submarine is a great room with a fresh and exciting theme. The interior design and decorations are on point and perfectly sets the atmosphere. The puzzles are good and differ both in type and complexity. Our game masters were very friendly and engaged and nearly as excited as we were about almost beating the all time record.`
			},
			locations: [{
				locationId: 'randers',
				opened: '20190701',
				closed: null,
				roomes: 1
			}]
		}),
	]
});


export {venueData};