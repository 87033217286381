const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'mosede-fort',
	name: 'Mosede Fort',	
	website: 'https://www.mosedefort.dk',
	locations: [{
		id: 'greve',
		cityId: 'greve',
		opened: '20140101',
		closed: '20221231',
		address: 'Mosede Strandvej 87A, 2670 Greve',
		coordinates: {lat: '55.56453', lng: '12.27419'},
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'the-angel-hunter',
			name: {da: 'Englejægeren #0', en: 'The Angel Hunter #0'},
			minutes: 50,
			minimumAge: 11,
			tagIds: ['kids'],
			locations: [{
				locationId: 'greve',
				opened: '20190522',
				closed: '20221231',
				rooms: 1
			}]
		})
	]
});


export {venueData};