const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'fabriksevent',
	name: 'Fabriksevent',
	website: 'https://fabriksevent.dk',
	locations: [{
		id: 'vejle',
		cityId: 'vejle',
		opened: '20170201',
		closed: '20200201',
		address: 'Sønderbrogade 34, 7160 Tørring',
		coordinates: {lat: '55.848234', lng: '9.482583'},
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'leif-mortensens-condo',
			name: {da: 'Leif Mortensens Lejlighed', en: 'Leif Mortensen\'s Condo'},			
			locations: [{
				locationId: 'vejle',
				opened: '20170201',
				closed: '20200201',
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-prison',
			name: {da: 'Fængslet', en: 'The Prison'},
			locations: [{
				locationId: 'vejle',
				opened: '20170201',
				closed: '20200201',
				rooms: 2
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-slaughterhouse',
			name: {da: 'Slagteriet', en: 'The Slaughterhouse'},
			locations: [{
				locationId: 'vejle',
				opened: '20170201',
				closed: '20200201',
				rooms: 1
			}]
		}),
	]
});

export {venueData};